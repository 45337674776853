import { useStores, useGlobalFetching } from '@shared/hooks';
import { updateFormByMobileApp } from '@shared/utils';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import type { FormBuilderUpdateHandler } from '@shared/types';

import { useHandleNextStep } from './hooks';
import {
  useHandleSubmitOnPaymentPage,
  useHandleFooter,
} from './smart-components-hooks';

type BFFBoxProps = {
  updateFormValue: FormBuilderUpdateHandler;
};

export const BFFBox = observer(({ updateFormValue }: BFFBoxProps) => {
  const {
    MainStore: {
      applicationStore: { deviceType, phoneFromMobileApp },
      initProductStore: { formBuilderIsMounted },
      authStore: { setDisableForm },
    },
  } = useStores();

  const isGlobalFetching = useGlobalFetching();

  // Smart components hooks
  useHandleSubmitOnPaymentPage(updateFormValue);
  useHandleFooter();

  // Other hooks
  useHandleNextStep();

  useEffect(() => {
    setDisableForm(isGlobalFetching);
  }, [isGlobalFetching]);

  // Добавление данных с мп в смарт компонент
  useEffect(() => {
    if (!formBuilderIsMounted) return;
    updateFormByMobileApp(updateFormValue, deviceType, phoneFromMobileApp);
  }, [phoneFromMobileApp, deviceType, formBuilderIsMounted]);

  return null;
});
