import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

import type { GetRisksResponse } from './types';
import type { AxiosError } from 'axios';

export const useQueryGetRisks = (code: string | undefined) => {
  const enabled = Boolean(code);

  return useQuery<GetRisksResponse, AxiosError<{ code: string }>>(
    ['get-risks'],
    fetchData<GetRisksResponse>({
      url: Endpoints.GET_RISKS,
      method: 'POST',
      data: {
        baseParameters: {
          productVersion: code,
        },
      },
    }),
    { enabled }
  );
};
