/* eslint-disable indent */
import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { CarInsuranceParameters } from '@entities/import-smart-components/car-insurance-parameters';
import { SmartComponentName } from '@product-config/constants';
import { CarInsuranceParametersAnalyticEvent } from '@pulse-smart-components-kit/car-insurance-parameters';
import { formattingPrice } from '@pulse-web-platform-core/utils';
import { Spinner } from '@pulse-web-ui/spinner';
import { FallbackSkeleton } from '@shared/components';
import { withMemo } from '@shared/hocs';
import { useStores } from '@shared/hooks';
import { addDays } from 'date-fns';
import { observer } from 'mobx-react-lite';
import {
  forwardRef,
  type Ref,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react';

import type {
  Franchise,
  InsuranceProductPeriod,
  VehicleRepairType,
  VehicleRegion,
} from './queries';
import type {
  CarInsuranceParametersValues,
  CarInsuranceParametersOptions,
} from '@pulse-smart-components-kit/car-insurance-parameters';
import type { SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';
import type { AnalyticEventsMap } from '@pulse-web-platform-core/types';

import { DEFAULT_FRANCHISE_OPTION } from './constants';
import {
  useQueryVehicleRegion,
  useQueryInsuranceProductPeriod,
  useQueryVehicleRepairType,
  useQueryFranchise,
  useQueryRegion,
} from './queries';
import {
  getDefaultFranchiseValue,
  getDefaultValues,
  getMaxCountDays,
  getMinCountDays,
} from './utils';

const TODAY_DATE = new Date();

const analyticEventsMap: AnalyticEventsMap<CarInsuranceParametersAnalyticEvent> =
  {
    [CarInsuranceParametersAnalyticEvent.ON_REGION_SELECTED]: {
      name: analyticEvents.regionSelected,
    },
    [CarInsuranceParametersAnalyticEvent.ON_DATE_SELECTED]: {
      name: analyticEvents.dateSelected,
    },
    [CarInsuranceParametersAnalyticEvent.ON_FRANCHISE_SELECTED]: {
      name: analyticEvents.franchiseSelected,
    },
  };

export const CarInsuranceParametersAdapter = withMemo(
  observer(
    forwardRef(
      (
        {
          value,
          onChange,
          isSubmitting,
        }: SmartComponentAdapterProps<Partial<CarInsuranceParametersValues>>,
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const {
          MainStore: {
            formStateStore: {
              formState: {
                CarSearch,
                CarNumber,
                CarInsuranceParameters: InsuranceParametersStore,
              },
              updateFormValue,
            },
            initProductStore: {
              initState: { extraParams, code },
            },
          },
        } = useStores();

        const [isLoading, setIsLoading] = useState(true);

        const carNumber = CarSearch?.carNumber ?? CarNumber?.carNumber;

        const { data: dataVehicleRegion, isLoading: isLoadingVehicleRegion } =
          useQueryVehicleRegion(code);
        const { data: dataRepair, isLoading: isLoadingRepair } =
          useQueryVehicleRepairType(code);
        const { data: dataPeriod, isLoading: isLoadingPeriod } =
          useQueryInsuranceProductPeriod(code);
        const { data: dataFranchise, isLoading: isLoadingFranchise } =
          useQueryFranchise(code);
        const { data: dataRegion, isLoading: isLoadingRegion } = useQueryRegion(
          {
            carNumber: carNumber,
            code,
          }
        );

        const isLoadings =
          isLoadingVehicleRegion ||
          isLoadingRepair ||
          isLoadingPeriod ||
          isLoadingFranchise ||
          isLoadingRegion;

        useEffect(() => {
          setIsLoading(isLoadings);
        }, [isLoadings]);

        const minDate = useMemo(() => {
          const minCountDays = getMinCountDays(extraParams);
          return addDays(TODAY_DATE, minCountDays);
        }, [extraParams]);

        const franchiseDefaultValue = useMemo(() => {
          return getDefaultFranchiseValue(extraParams);
        }, [extraParams]);

        useEffect(() => {
          const defaultValues = getDefaultValues(
            extraParams,
            minDate,
            dataRegion?.region
          );

          updateFormValue(SmartComponentName.CAR_INSURANCE_PARAMETERS, {
            ...InsuranceParametersStore,
            ...defaultValues,
            franchise: franchiseDefaultValue,
          });
        }, [franchiseDefaultValue, extraParams, minDate, dataRegion?.region]);

        const regions = useMemo(() => {
          if (!dataVehicleRegion?.vehicleRegions) {
            return [];
          }

          return dataVehicleRegion?.vehicleRegions.map(
            (item: VehicleRegion) => ({
              label: item.name,
              value: item.code,
            })
          );
        }, [dataVehicleRegion?.vehicleRegions]);

        const repairs = useMemo(() => {
          if (!dataRepair?.vehicleRepairTypes) {
            return [];
          }

          return dataRepair?.vehicleRepairTypes?.map(
            (item: VehicleRepairType) => ({
              label: item.name,
              value: item.code,
              id: item.code,
              describe: item.description,
            })
          );
        }, [dataRepair?.vehicleRepairTypes]);

        const periods = useMemo(() => {
          if (!dataPeriod?.insuranceProductPeriods) {
            return [];
          }

          return dataPeriod.insuranceProductPeriods.map(
            (item: InsuranceProductPeriod) => ({
              label: item.name,
              value: item.code,
            })
          );
        }, [dataPeriod?.insuranceProductPeriods]);

        const period = useMemo(() => {
          const maxCountDays = getMaxCountDays({
            hasKasko: Boolean(CarSearch?.haveValidPolicy),
            defaultProductValues: extraParams,
          });

          return {
            maxDate: addDays(TODAY_DATE, maxCountDays),
            minDate: minDate,
            periods: periods,
          };
        }, [CarSearch?.haveValidPolicy, extraParams, minDate, periods]);

        const franchises = useMemo(() => {
          if (!dataFranchise?.franchises) {
            return [DEFAULT_FRANCHISE_OPTION];
          }

          const franchises = dataFranchise.franchises.map(
            (item: Franchise) => ({
              label: formattingPrice(Number(item.name)),
              value: item.code,
            })
          );

          return [DEFAULT_FRANCHISE_OPTION, ...franchises];
        }, [dataFranchise?.franchises]);

        const options: CarInsuranceParametersOptions = {
          regions,
          repairs,
          period,
          franchises,
        };

        const handleAnalyticEventSend =
          useHandleSmartComponentsAnalyticEvents<CarInsuranceParametersAnalyticEvent>(
            analyticEventsMap
          );

        return (
          <Suspense
            fallback={
              <FallbackSkeleton height={274}>
                <Spinner />
              </FallbackSkeleton>
            }
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <CarInsuranceParameters
                value={value}
                onChange={onChange}
                isSubmitting={isSubmitting}
                options={options}
                ref={forwardRef}
                onAnalyticEventSend={handleAnalyticEventSend}
              />
            )}
          </Suspense>
        );
      }
    )
  )
);

CarInsuranceParametersAdapter.displayName = 'CarInsuranceParametersAdapter';
