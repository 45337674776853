import { DATE_FORMAT } from '@pulse-web-platform-core/constants';
import { LICENSE_REG_EXP } from '@pulse-web-platform-core/regexp';
import { parsingDocument } from '@shared/utils';
import { format } from 'date-fns';

import type { Driver } from '@pulse-smart-components-kit/drivers-data';

export const getVehicleDrivers = (drivers: Driver[]) => {
  return drivers?.map((item) => {
    const [series, number] = parsingDocument(
      item?.license ?? '',
      LICENSE_REG_EXP
    );

    return {
      lastName: item.surname,
      firstName: item.name,
      middleName: item?.middlename as string | undefined,
      birthDate: format(item.birthday ?? new Date(), DATE_FORMAT),
      drivingLicense: {
        licenseSeries: series.replace(' ', ''),
        licenseNumber: number.trim(),
        licenseIssueDate: format(item.licenseDate ?? new Date(), DATE_FORMAT),
        drivingStartDate: Number(item.yearOfStart) || 0,
      },
    };
  });
};
