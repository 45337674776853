import { convertDateByTimeZoneStrict } from '@pulse-web-platform-core/utils';
import { getHours } from 'date-fns';

import type { ExtraParam } from '@shared/types';

export const getMinCountDays = (defaultProductValues: ExtraParam[] = []) => {
  const moscowDate = convertDateByTimeZoneStrict(new Date());
  const hours = getHours(moscowDate);

  if (hours < 18) {
    const countDays =
      defaultProductValues.find((item) => item.code === 'minPoliceStartDate')
        ?.value ?? 0;

    return Number(countDays);
  }

  const countDays =
    defaultProductValues.find((item) => item.code === 'minPoliceStartDateLate')
      ?.value ?? 0;

  return Number(countDays);
};
