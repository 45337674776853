import { EventBus } from '@pulse-web-platform-core/event-bus';

export enum FormBuilderAction {
  UPDATE_FORM = 'update-form',
  UPDATE_FORM_ERRORS = 'update-form-errors',
  SUBMIT_FORM = 'submit-form',
  AUTH_USER = 'auth-user',
}

export const eventBus = new EventBus<FormBuilderAction>();
