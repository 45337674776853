import {
  CommonComponentName,
  SmartComponentName,
  CascoStep,
} from '../constants';
import { type CascoProductConfig } from '../types';

export const DEFAULT_APP_CONFIG: CascoProductConfig = {
  name: 'casco-base-flow',
  header: [
    {
      name: CommonComponentName.HEADER,
      id: CommonComponentName.HEADER,
    },
  ],
  footer: [
    {
      name: CommonComponentName.FOOTER,
      id: CommonComponentName.FOOTER,
    },
  ],
  submit: [
    {
      name: CommonComponentName.SUBMIT,
      id: CommonComponentName.SUBMIT,
    },
  ],
  flow: [
    {
      icon: 'Car',
      name: CascoStep.CALCULATION,
      components: [
        {
          name: SmartComponentName.CAR_NUMBER,
          id: SmartComponentName.CAR_NUMBER,
        },
        {
          name: SmartComponentName.CAR_SEARCH,
          id: SmartComponentName.CAR_SEARCH,
        },
        {
          name: SmartComponentName.DRIVERS,
          id: SmartComponentName.DRIVERS,
        },
      ],
    },
    {
      icon: 'Options',
      name: CascoStep.OPTIONS,
      components: [
        {
          name: SmartComponentName.CAR_INSURANCE_PARAMETERS,
          id: SmartComponentName.CAR_INSURANCE_PARAMETERS,
        },
      ],
    },
    {
      icon: 'Protect',
      name: CascoStep.FORMALIZATION,
      components: [
        {
          name: SmartComponentName.POLICY_IMPROVEMENT,
          id: SmartComponentName.POLICY_IMPROVEMENT,
        },
      ],
    },
    {
      icon: 'Peoples',
      name: CascoStep.CLIENTS,
      components: [
        {
          name: SmartComponentName.USER_AUTH_WITH_PASSPORT,
          id: SmartComponentName.USER_AUTH_WITH_PASSPORT,
        },
        {
          name: SmartComponentName.POLICYHOLDER_DATA,
          id: SmartComponentName.POLICYHOLDER_DATA,
        },
        {
          name: SmartComponentName.POLICYHOLDER_FORM,
          id: SmartComponentName.POLICYHOLDER_FORM,
        },
        {
          name: SmartComponentName.CAR_OWNER,
          id: SmartComponentName.CAR_OWNER,
        },
        {
          name: SmartComponentName.CAR_DOCUMENT,
          id: SmartComponentName.CAR_DOCUMENT,
        },
        {
          name: SmartComponentName.DRIVERS_DATA,
          id: SmartComponentName.DRIVERS_DATA,
        },
      ],
    },
    {
      icon: 'Wallet',
      name: CascoStep.PAYMENT,
      components: [
        {
          name: SmartComponentName.CHECK_POLICY,
          id: SmartComponentName.CHECK_POLICY,
        },
        {
          name: SmartComponentName.B2P,
          id: SmartComponentName.B2P,
        },
      ],
    },
  ],
  globalVariables: {
    otpStep: 4,
    isShowBackButton: true,
  },
};
