import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { PolicyImprovement } from '@entities/import-smart-components/policy-improvement';
import {
  type PolicyImprovementValues,
  type PolicyImprovementOptions,
  PolicyImprovementAnalyticEvent,
} from '@pulse-smart-components-kit/policy-improvement';
import { FallbackSkeleton } from '@shared/components';
import { withMemo } from '@shared/hocs';
import { useHandleErrorRequest, useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, type Ref, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';
import type { AnalyticEventsMap } from '@pulse-web-platform-core/types';

import { i18nDefaultValues } from './i18n';
import { useQueryGetRisks } from './queries';

const analyticEventsMap: AnalyticEventsMap<PolicyImprovementAnalyticEvent> = {
  [PolicyImprovementAnalyticEvent.ON_ITEM_SELECTED]: {
    name: analyticEvents.riskGapSelected,
  },
};

export const PolicyImprovementAdapter = withMemo(
  observer(
    forwardRef(
      (
        {
          value,
          onChange,
          isSubmitting,
          fieldState,
        }: SmartComponentAdapterProps<PolicyImprovementValues>,
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const { t } = useTranslation();

        const {
          MainStore: {
            initProductStore: {
              initState: { code },
            },
          },
        } = useStores();

        const { data, isLoading, status, refetch, error } =
          useQueryGetRisks(code);

        useHandleErrorRequest({
          status,
          refetch,
          error,
          isLoading,
        });

        const options: PolicyImprovementOptions = useMemo(() => {
          return {
            title: t('SMART:PolicyImprovementAdapter.title', {
              defaultValue: i18nDefaultValues.PolicyImprovementAdapter.title,
            }),
            improvementItems: data?.risks,
          };
        }, [data?.risks]);

        const handleAnalyticEventSend =
          useHandleSmartComponentsAnalyticEvents<PolicyImprovementAnalyticEvent>(
            analyticEventsMap
          );

        if (!data?.risks?.length) {
          return <FallbackSkeleton height={274} />;
        }

        return (
          <Suspense fallback={<FallbackSkeleton height={274} />}>
            <PolicyImprovement
              ref={forwardRef}
              value={value}
              onChange={onChange}
              isSubmitting={isSubmitting}
              fieldState={fieldState}
              options={options}
              onAnalyticEventSend={handleAnalyticEventSend}
            />
          </Suspense>
        );
      }
    )
  )
);

PolicyImprovementAdapter.displayName = 'PolicyImprovementAdapter';
