import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { AuthBox, AuthBoxAnalyticEvent } from '@features/auth-box';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';

import type { AnalyticEventsMap } from '@pulse-web-platform-core/types';
import type {
  FormBuilderUpdateErrorHandler,
  FormBuilderUpdateHandler,
} from '@shared/types';

import { getRegistrationData } from './utils';

const analyticEventsMap: AnalyticEventsMap<AuthBoxAnalyticEvent> = {
  [AuthBoxAnalyticEvent.ON_SIGNUP_START]: {
    name: analyticEvents.authSignupStart,
    isSingle: true,
  },
  [AuthBoxAnalyticEvent.ON_REGISTRATION_START]: {
    name: analyticEvents.authRegistrationStart,
    isSingle: true,
  },
  [AuthBoxAnalyticEvent.ON_SIGNUP_SUCCESS]: {
    name: analyticEvents.authSignupSuccess,
    isSingle: true,
  },
  [AuthBoxAnalyticEvent.ON_REGISTRATION_SUCCESS]: {
    name: analyticEvents.authRegistrationSuccess,
    isSingle: true,
  },
};

type AuthBoxAdapterProps = {
  updateFormValue: FormBuilderUpdateHandler;
  setFormValueError: FormBuilderUpdateErrorHandler;
};

export const AuthBoxAdapter = observer(
  ({ updateFormValue, setFormValueError }: AuthBoxAdapterProps) => {
    const {
      MainStore: {
        formStateStore: {
          formState: { UserAuthWithPassport: userAuthWithPassport },
        },
        initProductStore: { regUtm },
        authStore: { promoAgreement },
        applicationStore: { setIsRegistration },
      },
    } = useStores();

    const registrationData = getRegistrationData({
      userAuthWithPassport,
      regUtm,
      promoAgreement,
    });

    const handleAnalyticEventSend =
      useHandleSmartComponentsAnalyticEvents<AuthBoxAnalyticEvent>(
        analyticEventsMap
      );

    return (
      <AuthBox
        registrationData={registrationData}
        updateFormValue={updateFormValue}
        setFormValueError={setFormValueError}
        onAnalyticEventSend={handleAnalyticEventSend}
        setIsRegistration={setIsRegistration}
      />
    );
  }
);

AuthBoxAdapter.displayName = 'AuthBoxAdapter';
