import { makeAutoObservable } from 'mobx';

import type { CascoProductConfig } from '@product-config/index';
export class ThemesStore {
  themeUrl?: string;
  appConfig?: CascoProductConfig;

  constructor() {
    makeAutoObservable(this);
  }

  setThemeUrl = (e: string) => {
    this.themeUrl = e;
  };

  setAppConfig = (e: CascoProductConfig) => {
    this.appConfig = e;
  };
}
