import { action, makeAutoObservable, observable, runInAction } from 'mobx';

import type { Main } from '@app/store';
import type { SubmitResponse } from '@entities/adapters/check-policy-adapter';
import type { Car } from '@pulse-smart-components-kit/common';
import type { ProductPrice } from '@pulse-web-platform-core/types';

export class ProductStore {
  private mainStore: Main;
  pulseClient?: boolean; // Связь с чекбоксом “Я клиент СК «Пульс»” на авторизации
  price: ProductPrice = { premiumAndDelta: '20000', promoCode: '' }; // Цена в футере
  orderData?: SubmitResponse = undefined; // Данные полученные с post запроса submit-order
  hasPricesCalcErr: boolean = false; // Ошибка в цене, отрисовка в cостояние error, если сумма превышает досигаемой.
  isPolicyBanned: boolean = false; // Признак ошибки о невозможности оформления полиса.
  agentLogin?: string;

  // Добавлены в Каско
  car: Car = { requestId: '' };
  carSearchAttemptsCount = 0;
  hasUnknownCarNumberError = false;
  isCarNumberNotReceived = false;
  isCarNumberSmartComponentShowing = true;
  isEmailNotUnique = false;
  isManualCarInput: boolean = false; //Ручной ввод ТС
  isPolicyholderTheOwner = false; // Чекбокс "страхователь является владельцем".
  stringifiedSubmitData = '';
  wantFindCar: boolean = false; // Для поиска номера машины

  constructor(mainStore: Main) {
    this.mainStore = mainStore;
    makeAutoObservable(this, {
      pulseClient: observable,
      setPulseClient: action,
      setOrderData: action,
      price: observable,
      setPrice: action,
      setHasPricesCalcErr: action,
      agentLogin: observable,
      isPolicyBanned: observable,
      setAgentLogin: action,
      clearPromoCode: action,
      setIsPolicyBanned: action,

      car: observable,
      carSearchAttemptsCount: observable,
      hasUnknownCarNumberError: observable,
      isCarNumberNotReceived: observable,
      isCarNumberSmartComponentShowing: observable,
      isEmailNotUnique: observable,
      isManualCarInput: observable,
      isPolicyholderTheOwner: observable,
      stringifiedSubmitData: observable,
      wantFindCar: observable,

      setCar: action,
      incrementCarSearchAttemptsCount: action,
      setHasUnknownCarNumberError: action,
      setIsCarNumberNotReceived: action,
      setIsCarNumberSmartComponentShowing: action,
      setIsEmailNotUnique: action,
      setIsManualCarInput: action,
      setIsPolicyholderTheOwner: action,
      setWantFindCar: action,
      setStringifiedSubmitData: action,
    });
  }

  setPrice = (e: Partial<ProductPrice>) => {
    this.price = { ...this.price, ...e };
  };

  clearPromoCode = () => {
    this.price = {
      ...this.price,
      isSuccessfulPromo: undefined,
      premiumAndDeltaPromo: undefined,
    };
  };

  setHasPricesCalcErr = (e: boolean) => {
    this.hasPricesCalcErr = e;
  };

  setOrderData = (e: SubmitResponse) => {
    this.orderData = e;
  };

  setPulseClient = (e: boolean) => {
    this.pulseClient = e;
  };

  setIsPolicyBanned = (e: boolean) => {
    this.isPolicyBanned = e;
    runInAction(() => {
      this.mainStore.applicationStore.setIsHeaderShowing(!e);
      this.mainStore.applicationStore.setIsFooterShowing(!e);
      this.mainStore.applicationStore.setIsSubmitShowing(!e);
      this.mainStore.applicationStore.setIsSmartComponentsShowing(!e);
    });
  };

  setAgentLogin = (e: string) => {
    this.agentLogin = e;
  };

  // Добавлено в Каско
  setCar = (e: Car) => {
    this.car = e;
  };

  incrementCarSearchAttemptsCount = () => {
    this.carSearchAttemptsCount += 1;
  };

  setHasUnknownCarNumberError = (e: boolean) => {
    this.hasUnknownCarNumberError = e;
  };

  setIsCarNumberNotReceived = (e: boolean) => {
    this.isCarNumberNotReceived = e;
  };

  setIsCarNumberSmartComponentShowing = (e: boolean) => {
    this.isCarNumberSmartComponentShowing = e;
  };

  setIsManualCarInput = (e: boolean) => {
    this.isManualCarInput = e;
    this.isCarNumberSmartComponentShowing = false;
  };

  setIsEmailNotUnique = (e: boolean) => {
    this.isEmailNotUnique = e;
  };

  setIsPolicyholderTheOwner = (e: boolean) => {
    this.isPolicyholderTheOwner = e;
  };

  setStringifiedSubmitData = (data: string) => {
    this.stringifiedSubmitData = data;
  };

  setWantFindCar = (e: boolean) => {
    this.wantFindCar = e;
  };
}
