import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { CarOwner } from '@entities/import-smart-components/car-owner';
import { SmartComponentName } from '@product-config/constants';
import { CarOwnerAnalyticEvent } from '@pulse-smart-components-kit/car-owner';
import { withMemo } from '@shared/hocs';
import { useGetDadataRequest, useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, type Ref, useMemo, useCallback } from 'react';

import type {
  CarOwnerOptions,
  CarOwnerValues,
} from '@pulse-smart-components-kit/car-owner';
import type { SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';
import type { AnalyticEventsMap } from '@pulse-web-platform-core/types';

const analyticEventsMap: AnalyticEventsMap<CarOwnerAnalyticEvent> = {
  [CarOwnerAnalyticEvent.ON_INSURANT_IS_OWNER]: {
    name: analyticEvents.insurantisOwner,
  },
  [CarOwnerAnalyticEvent.ON_DATA_SELECTED]: {
    name: analyticEvents.ownerDataSelected,
  },
};

const CarOwnerWithMemo = withMemo(CarOwner);

export const CarOwnerAdapter = withMemo(
  observer(
    forwardRef(
      (
        {
          value,
          onChange,
          isSubmitting,
          fieldState,
        }: SmartComponentAdapterProps<CarOwnerValues>,
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const {
          MainStore: {
            formStateStore: {
              formState: {
                PolicyholderForm,
                UserAuthWithPassport,
                CarOwner: CarOwnerData,
              },
              updateFormValue,
            },
            productStore: { isPolicyholderTheOwner, setIsPolicyholderTheOwner },
          },
        } = useStores();

        const onIsPolicyholderTheOwnerChange = useCallback(
          (isPolicyholderTheOwner: boolean) => {
            if (isPolicyholderTheOwner) {
              updateFormValue(SmartComponentName.CAR_OWNER, {
                ...CarOwnerData,
                birthday: PolicyholderForm?.birthday,
                middlename: PolicyholderForm?.middlename,
                name: PolicyholderForm?.name,
                surname: PolicyholderForm?.surname,
                passport: PolicyholderForm?.passport,
                passportDate: PolicyholderForm?.passportDate,
                passportByWho: PolicyholderForm?.passportByWho,
                passportCode: PolicyholderForm?.passportCode,
                address: PolicyholderForm?.address,
              });
            } else {
              updateFormValue(SmartComponentName.CAR_OWNER, {
                ...CarOwnerData,
                birthday: null,
                middlename: '',
                name: '',
                surname: '',
                passport: '',
                passportDate: null,
                passportByWho: '',
                passportCode: '',
                address: { value: '' },
              });
            }
            setIsPolicyholderTheOwner(isPolicyholderTheOwner);
          },
          [
            isPolicyholderTheOwner,
            setIsPolicyholderTheOwner,
            CarOwnerData,
            PolicyholderForm,
          ]
        );

        const customRequest = useGetDadataRequest();

        const options: CarOwnerOptions = useMemo(
          () => ({
            isPolicyholderTheOwner,
            onIsPolicyholderTheOwnerChange,
            dadataSuggestionsRequest: customRequest,
          }),
          [isPolicyholderTheOwner, onIsPolicyholderTheOwnerChange]
        );

        const handleAnalyticEventSend =
          useHandleSmartComponentsAnalyticEvents<CarOwnerAnalyticEvent>(
            analyticEventsMap
          );

        const hasAuthorized = UserAuthWithPassport?.hasAuthorized;

        if (!hasAuthorized) {
          return null;
        }

        return (
          <Suspense>
            <CarOwnerWithMemo
              ref={forwardRef}
              value={value}
              onChange={onChange}
              isSubmitting={isSubmitting}
              options={options}
              fieldState={fieldState}
              onAnalyticEventSend={handleAnalyticEventSend}
            />
          </Suspense>
        );
      }
    )
  )
);

CarOwnerAdapter.displayName = 'CarOwnerAdapter';
