/* eslint-disable indent */
import { ErrorCode } from '@pulse-web-platform-core/constants';
import { Endpoints } from '@shared/constants';
import { axiosInstance, refreshToken } from '@shared/utils';
import { useEffect } from 'react';

// import { analyticEvents } from '@app/web-analytic';
import type { AuthTokensType } from '@pulse-web-platform-core/types';
import type { AxiosError, AxiosResponse } from 'axios';

import { useStores } from './use-stores';

type useAxiosInterceptorsProps = (authorization: AuthTokensType) => void;
export const useAxiosInterceptors = (setToken: useAxiosInterceptorsProps) => {
  const {
    MainStore: {
      // applicationStore: { sendSingleAnalyticEvent },
    },
  } = useStores();

  const handleResponse = (response: AxiosResponse): AxiosResponse => {
    if (response?.data?.csrfToken) {
      localStorage.setItem('csrfToken', response?.data?.csrfToken);
    }

    const accessToken =
      response.data?.accessToken || response.data?.authorization?.accessToken;

    if (accessToken) {
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }

    // switch (response?.config?.url) {
    //   case Endpoints.CONFIRMAUTH:
    //     if (response?.data?.name) {
    //       sendSingleAnalyticEvent(analyticEvents.travelSignupSuccess);
    //     }
    //     sendSingleAnalyticEvent(analyticEvents.travelerEnterCodeSuccess);
    //     break;
    //   case Endpoints.REG:
    //     sendSingleAnalyticEvent(analyticEvents.travelRegistrationSuccess);
    //     break;
    // }

    return response;
  };
  const handleError = async (error: AxiosError<any>): Promise<void> => {
    const status = error?.response?.status;
    const data = error?.response?.data;
    const configUrl = error?.config?.url;

    if (
      ((status === 400 && data?.code === ErrorCode.TECHNICAL_ERROR) ||
        status === 500) &&
      configUrl &&
      configUrl?.indexOf(Endpoints.PAYMENT_STAGE) === -1
    ) {
      throw error;
    }

    if (
      status === 400 &&
      data?.code === ErrorCode.BUSINESS_ERROR &&
      error?.config?.url !== Endpoints.COMPLETE_PAYMENT
    ) {
      throw error;
    }

    if (
      error?.config?.url &&
      error?.config?.url?.indexOf(Endpoints.PAYMENT_STAGE) >= 0
    ) {
      // Возвращаем ошибку без установки флага для показа модального окна с ошибкой "Что-то пошло не так"
      throw error;
    }

    if (
      status == 401 &&
      error?.response?.request.responseURL.includes('refresh-token')
    ) {
      setToken(undefined);
      localStorage.removeItem('csrfToken');

      throw Error('REFRESH TOKEN UNAUTHORIZED');
    }

    if (status === 401) {
      setToken(undefined);
      if (localStorage.getItem('csrfToken')) {
        try {
          const result = await refreshToken();

          if (result?.data) {
            const {
              authorization: { csrfToken, accessToken },
            } = result.data;

            if (csrfToken) {
              localStorage.setItem('csrfToken', csrfToken);
            }

            setToken(result.data);

            if (error?.config?.headers) {
              error.config.headers.Authorization = `Bearer ${accessToken}`;

              return axiosInstance.request(error.config);
            }
            return;
          }
        } catch (error) {
          localStorage.removeItem('csrfToken');
          location.reload();
          throw error;
        }
      }
    } else {
      throw error;
    }
  };

  const interceptor = axiosInstance.interceptors.response.use(
    handleResponse,
    handleError
  );

  useEffect(() => {
    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, [interceptor]);
};
