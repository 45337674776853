import { SmartComponentName } from '@product-config/constants';
import { ErrorCode, PublicRoutes } from '@pulse-web-platform-core/constants';
import { hasError } from '@pulse-web-platform-core/utils';
import { Endpoints } from '@shared/constants';
import { useStores } from '@shared/hooks';
import { fetchData } from '@shared/utils';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import type { SubmitRequest, SubmitResponse } from './types';
import type { ResponseError } from '@pulse-web-platform-core/types';
import type { AxiosError } from 'axios';

import { requestBody } from './schema';

export const useQuerySubmit = (data: SubmitRequest | undefined) => {
  const navigate = useNavigate();
  const {
    MainStore: {
      applicationStore: { setIsPaymentStep, retryPayment, setRetryPayment },
      initProductStore: { setInitOrder, setInitOrderError },
      errorStore: { setErrorRetry },
      authStore: { authTokens, accessToken, setDisableForm },
      productStore: {
        setOrderData,
        setIsPolicyBanned,
        stringifiedSubmitData,
        setStringifiedSubmitData,
      },
      formStateStore: { formState, updateFormValue },
    },
  } = useStores();

  const enabled = requestBody.isValidSync(data);

  useEffect(() => {
    if (JSON.stringify(data) !== stringifiedSubmitData) {
      setStringifiedSubmitData(JSON.stringify(data));
    }
  }, [JSON.stringify(data), stringifiedSubmitData]);

  const {
    data: submitData,
    isLoading,
    isFetching,
    status,
    refetch,
    remove,
    error,
  } = useQuery<SubmitResponse, AxiosError<{ code: string }>>(
    ['submit', data],
    fetchData<SubmitResponse>({
      url: Endpoints.SUBMIT_ORDER,
      method: 'POST',
      data,
    }),
    { enabled }
  );

  useEffect(() => {
    setDisableForm(isLoading);
    if (isLoading && !retryPayment) {
      updateFormValue(SmartComponentName.B2P, {
        initOrder: undefined,
        showInitOrder: false,
        isValid: true,
      });
      setInitOrder(undefined);
      setInitOrderError(undefined);
      setIsPaymentStep(false);
      setErrorRetry(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (retryPayment) {
      refetch();
    }
  }, [retryPayment]);

  useEffect(() => {
    if (!isLoading && submitData && accessToken) {
      setDisableForm(false);
      setOrderData(submitData);
      setErrorRetry(false);
      setRetryPayment(false);
      updateFormValue(SmartComponentName.CHECK_POLICY, {
        documentsList: submitData.clientDocument,
        token: accessToken,
        acceptRequirements: formState?.CheckPolicy?.acceptRequirements,
        isValid: formState?.CheckPolicy?.isValid,
      });
    }

    if (!isLoading && error) {
      if (hasError(error as ResponseError, ErrorCode.USER_DATA_ERROR)) {
        setIsPolicyBanned(true);
        navigate(PublicRoutes.POLICY_BANNED);
      }
      setDisableForm(false);
    }
  }, [isLoading, submitData, error, authTokens]);

  return {
    data: submitData,
    isLoading,
    status,
    refetch,
    remove,
    error,
    isFetching,
  };
};
