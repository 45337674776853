import { SmartComponentName } from '@product-config/constants';
import { useStores } from '@shared/hooks';
import { useEffect } from 'react';

import type { FormBuilderUpdateHandler } from '@shared/types';

export const useHandleSubmitOnPaymentPage = (
  updateFormValue: FormBuilderUpdateHandler
) => {
  const {
    MainStore: {
      applicationStore: {
        activeStep,
        isPaymentStep,
        setIsPaymentStep,
        flowConfig: { maxSteps },
      },
      initProductStore: { setInitOrder, setInitOrderError },
      authStore: { setDisableForm },
    },
  } = useStores();

  useEffect(() => {
    if (activeStep !== maxSteps && isPaymentStep) {
      setIsPaymentStep(false);
      updateFormValue(SmartComponentName.CHECK_POLICY, {
        acceptRequirements: false,
        isValid: false,
      });
      updateFormValue(SmartComponentName.B2P, {
        initOrder: undefined,
        showInitOrder: false,
        isValid: true,
      });
      setDisableForm(false);
      setInitOrder(undefined);
      setInitOrderError(undefined);
      setIsPaymentStep(false);
    }
  }, [activeStep]);
};
