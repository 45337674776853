import { PlatformType } from '@shared/constants';
import { v4 as uuidv4 } from 'uuid';

import type { RawAxiosRequestHeaders } from 'axios';

import { axiosInstance } from './instance-axios';

export const refreshToken = () => {
  let headers: RawAxiosRequestHeaders = {
    'X-Process-ID': uuidv4(),
    'Content-type': 'application/json',
    'X-Platform-Type': PlatformType,
  };

  const csrfToken = localStorage.getItem('csrfToken');

  if (csrfToken) {
    headers = {
      ...headers,
      'X-CSRF-Token': csrfToken,
    };
  }

  return axiosInstance.post(
    `${window.envUrls.BASEURL}/v1/user/refresh-token`,
    {},
    {
      withCredentials: true,
      headers,
    }
  );
};
