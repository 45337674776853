import { SmartComponentName } from '@product-config/constants';
import { ErrorCode } from '@pulse-web-platform-core/constants';
import { useStores } from '@shared/hooks';
import { getInitOrderData, useQueryInitOrder } from '@shared/queries';
import { useEffect } from 'react';

import type { SubmitResponse } from '@entities/adapters/check-policy-adapter';
import type { ContractDataType } from '@pulse-web-platform-core/types';
import type { FormBuilderUpdateHandler } from '@shared/types';

export const useInitOrder = (
  updateFormValue: FormBuilderUpdateHandler,
  resSubmitOrder: SubmitResponse | undefined
) => {
  const {
    MainStore: {
      applicationStore: { isPaymentStep, retryPayment, isExternalPayment },
      initProductStore: { setInitOrder, setInitOrderError },
      errorStore: { setErrorRetry },
      authStore: { profile, accessToken, setDisableForm },
    },
  } = useStores();

  const contractData: ContractDataType = getInitOrderData(
    resSubmitOrder,
    profile
  );

  const {
    isLoading,
    data: resInit,
    refetch: refetchInit,
    error: errorInit,
  } = useQueryInitOrder({ data: contractData, accessToken });

  useEffect(() => {
    if (!isLoading && resInit) {
      updateFormValue(SmartComponentName.B2P, {
        isExternalPayment,
        initOrder: resInit,
        showInitOrder: false, // активность оплаты
        isValid: true,
      });
      setErrorRetry(false);
      setInitOrder(resInit);
      if (
        !isLoading &&
        errorInit?.response?.data?.code === ErrorCode.BUSINESS_ERROR
      ) {
        setInitOrderError({ code: ErrorCode.BUSINESS_ERROR });
      }
    }
  }, [isLoading, resInit, errorInit]);

  useEffect(() => {
    if (isPaymentStep && resSubmitOrder && !retryPayment) {
      setErrorRetry(false);
      setDisableForm(true);
      refetchInit();
    }
  }, [isPaymentStep, resSubmitOrder]);

  return { refetchInit, resInit };
};
