export enum SmartComponentName {
  CAR_NUMBER = 'CarNumber',
  CAR_SEARCH = 'CarSearch',
  DRIVERS = 'Drivers',
  CAR_INSURANCE_PARAMETERS = 'CarInsuranceParameters',
  POLICY_IMPROVEMENT = 'PolicyImprovement',
  USER_AUTH_WITH_PASSPORT = 'UserAuthWithPassport',
  POLICYHOLDER_DATA = 'PolicyholderData',
  POLICYHOLDER_FORM = 'PolicyholderForm',
  CAR_OWNER = 'CarOwner',
  CAR_DOCUMENT = 'CarDocument',
  DRIVERS_DATA = 'DriversData',
  CHECK_POLICY = 'CheckPolicy',
  B2P = 'B2P',
}
