import type { CompletePaymentRequest } from '@shared/queries';
import type { GetSubmitOrderResponse, InitOrderResponse } from '@shared/types';

export const getCompletePaymentData = (
  resSubmitOrder: GetSubmitOrderResponse | undefined,
  resInit?: InitOrderResponse
): CompletePaymentRequest => ({
  subscriptionId: resSubmitOrder?.subscriptionId || '',
  paymentDocumentId: resSubmitOrder?.billId || '',
  operationId: '',
  id: resInit?.id,
  contractId: resSubmitOrder?.contractId || '',
  amount: resSubmitOrder?.premiumAndDelta || '',
  paymentGateOrder: resInit?.paymentGateOrder,
  consents: [
    {
      consentType: '001',
    },
  ],
});
