import type { FallbackSkeletonProps } from './fallback-skeleton.types';

import { Wrapper } from './fallback-skeleton.styles';

export const FallbackSkeleton = ({
  height,
  transparent,
  children,
}: FallbackSkeletonProps) => {
  return (
    <Wrapper height={height} transparent={transparent}>
      {children}
    </Wrapper>
  );
};
