import i18n from '@app/i18n/config';
import { formattingPrice } from '@pulse-web-platform-core/utils';

export const formattingFranchise = (franchise: string) => {
  const value = Number(franchise);

  if (value) {
    return formattingPrice(value);
  }

  return i18n.t('COMMON:options.noFranchise');
};
