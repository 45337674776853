import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

import type { Car } from '@pulse-smart-components-kit/common';

export const useQueryCarData = (
  code?: string,
  carNumber?: string,
  enabled: boolean = false
) => {
  return useQuery(
    ['get-car', code, carNumber],
    fetchData<Car>({
      url: `${Endpoints.GET_CAR}?regNum=${carNumber}&productVersion=${code}`,
      method: 'GET',
    }),
    { enabled }
  );
};
