/* eslint-disable indent */
import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { CarSearch } from '@entities/import-smart-components/car-search';
import { SmartComponentName } from '@product-config/constants';
import {
  CarSearchAnalyticEvent,
  type CarData,
  type CarSearchValues,
} from '@pulse-smart-components-kit/car-search';
import {
  DEFAULT_OPTION,
  type SmartComponentAdapterProps,
  useUpdateEffect,
} from '@pulse-smart-components-kit/common';
import { FallbackSkeleton } from '@shared/components';
import { withMemo } from '@shared/hocs';
import { useHandleManualInput, useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import {
  type Ref,
  Suspense,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import type {
  AnalyticEventsMap,
  VehicleData,
} from '@pulse-web-platform-core/types';

import { useHandleCarData } from './hooks';
import {
  useQueryGetBrands,
  useQueryGetModels,
  useQueryGetYears,
  useQueryGetPowers,
  useQueryGetCarPrice,
} from './queries';

const analyticEventsMap: AnalyticEventsMap<CarSearchAnalyticEvent> = {
  [CarSearchAnalyticEvent.ON_BRAND_SELECTED]: {
    name: analyticEvents.carBrandSelected,
  },
  [CarSearchAnalyticEvent.ON_MODEL_SELECTED]: {
    name: analyticEvents.carModelSelected,
  },
  [CarSearchAnalyticEvent.ON_YEAR_SELECTED]: {
    name: analyticEvents.carYearSelected,
  },
  [CarSearchAnalyticEvent.ON_POWER_SELECTED]: {
    name: analyticEvents.carPowerSelected,
  },
  [CarSearchAnalyticEvent.ON_SUM_SELECTED]: {
    name: analyticEvents.carSumSelected,
  },
  [CarSearchAnalyticEvent.ON_VALID_KASKO]: {
    name: analyticEvents.validKasko,
  },
  [CarSearchAnalyticEvent.ON_KREDIT_AVTO]: {
    name: analyticEvents.kreditAvto,
  },
  [CarSearchAnalyticEvent.ON_MILEAGE_SELECTED]: {
    name: analyticEvents.carMileageSelected,
  },
  [CarSearchAnalyticEvent.ON_VIN_SELECTED]: {
    name: analyticEvents.carVinSelected,
  },
  [CarSearchAnalyticEvent.ON_CHANGE_CAR_DATA]: {
    name: analyticEvents.changeCarData,
  },
  [CarSearchAnalyticEvent.ON_CHANGE_CAR_DATA_ACCEPTED]: {
    name: analyticEvents.changeCarDataAccepted,
  },
  [CarSearchAnalyticEvent.ON_REG_NUMBER_SELECTED]: {
    name: analyticEvents.regNumberSelected,
  },
  [CarSearchAnalyticEvent.ON_REG_NUMBER_SEARCH]: {
    name: analyticEvents.regNumberSearch,
  },
  [CarSearchAnalyticEvent.ON_REG_NUMBER_NOT_FOUND]: {
    name: analyticEvents.regNumberNotFound,
  },
};

const getOptionsFromVehicleData = (vehicleData: VehicleData[]) =>
  vehicleData.map(({ code, name }) => ({
    label: name,
    value: code,
  }));

export const CarSearchAdapter = withMemo(
  observer(
    forwardRef(
      (
        {
          value,
          onChange,
          isSubmitting,
        }: SmartComponentAdapterProps<Partial<CarSearchValues>>,
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const {
          MainStore: {
            initProductStore: {
              initState: {
                code,
                minProductLimit,
                maxProductLimit,
                extraParams,
              },
            },
            productStore: {
              car,
              setCar,
              isManualCarInput,
              isCarNumberSmartComponentShowing,
              hasUnknownCarNumberError,
              setHasUnknownCarNumberError,
              isCarNumberNotReceived,
              setIsCarNumberNotReceived,
            },
            formStateStore: {
              formState: { CarInsuranceParameters },
              updateFormValue,
            },
          },
        } = useStores();

        const carNumberWithoutMask = value?.carNumber?.replaceAll(/[_\s]/g, '');

        const { isFoundAllCarData, handleFindCar } =
          useHandleCarData(carNumberWithoutMask);

        const [brandId, setBrandId] = useState<string | undefined>(
          value?.car?.brand?.value
        );
        const [modelId, setModelId] = useState<string | undefined>(
          value?.car?.model?.value
        );
        const [yearId, setYearId] = useState<string | undefined>(
          value?.car?.manufactureYear?.value
        );
        const [powerId, setPowerId] = useState<string | undefined>(
          value?.car?.power?.value
        );

        const region = useMemo(
          () =>
            extraParams?.find(({ code }) => code === 'defaultRegion')?.value,
          [extraParams]
        );

        const { refetch: refetchBrands, data: brandsData } =
          useQueryGetBrands(code);
        const { data: modelsData } = useQueryGetModels({ code, brandId });
        const { data: yearsData } = useQueryGetYears({
          code,
          brandId,
          modelId,
        });
        const { data: powersData } = useQueryGetPowers({
          code,
          brandId,
          modelId,
          yearId,
        });

        const { refetch: refetchCarPrice, data: carPriceResponse } =
          useQueryGetCarPrice({
            code,
            brandId,
            modelId,
            yearId,
            powerId,
            region,
          });

        const handleFindBrands = useCallback(() => {
          if (!code) return;
          refetchBrands();
        }, [code, refetchBrands]);

        const handleFindModels = useCallback((brand: string) => {
          setBrandId(brand);
          // Отчистка идентификаторов которые зависят от id бренда
          setModelId(undefined);
          setYearId(undefined);
          setPowerId(undefined);
        }, []);

        const handleFindYears = useCallback((model: string) => {
          setModelId(model);
          // Отчистка идентификаторов которые зависят от id модели
          setYearId(undefined);
          setPowerId(undefined);
        }, []);

        const handleFindPowers = useCallback((year: string) => {
          setYearId(year);
          // Отчистка идентификаторов которые зависят от id года
          setPowerId(undefined);
        }, []);

        const handleFindCarPrice = useCallback((data: CarData) => {
          setBrandId(data.brand?.value);
          setModelId(data.model?.value);
          setYearId(data.manufactureYear?.value);
          setPowerId(data.power?.value);
        }, []);

        const brands = useMemo(() => {
          if (!isFoundAllCarData && !brandsData?.data?.length && brandId)
            return [DEFAULT_OPTION];
          return brandsData?.data
            ? getOptionsFromVehicleData(brandsData.data)
            : [];
        }, [brandsData?.data, isFoundAllCarData, brandId]);

        const models = useMemo(() => {
          if (!isFoundAllCarData && !modelsData?.data?.length && modelId)
            return [DEFAULT_OPTION];

          return modelsData?.data
            ? getOptionsFromVehicleData(modelsData.data)
            : [];
        }, [modelsData?.data, isFoundAllCarData, modelId]);

        const years = useMemo(() => {
          if (!isFoundAllCarData && !yearsData?.data?.length && yearId)
            return [DEFAULT_OPTION];

          return yearsData?.data
            ? getOptionsFromVehicleData(yearsData.data)
            : [];
        }, [yearsData?.data, isFoundAllCarData, yearId]);

        const powers = useMemo(() => {
          if (!isFoundAllCarData && !powersData?.data?.length && powerId)
            return [DEFAULT_OPTION];

          return powersData?.data
            ? getOptionsFromVehicleData(powersData.data)
            : [];
        }, [powersData?.data, isFoundAllCarData, powerId]);

        const handleManualInput = useHandleManualInput(carNumberWithoutMask);

        const handleCarNumberIsNotReceived = useCallback(() => {
          setIsCarNumberNotReceived(true);
          updateFormValue(SmartComponentName.CAR_DOCUMENT, {});
          handleManualInput();
        }, [handleManualInput, setIsCarNumberNotReceived]);

        const handleCarNumberIsReceived = useCallback(() => {
          setIsCarNumberNotReceived(false);
        }, [setIsCarNumberNotReceived]);

        const options = useMemo(
          () => ({
            onFindCar: handleFindCar,
            onFindBrands: handleFindBrands,
            onFindModels: handleFindModels,
            onFindYears: handleFindYears,
            onFindPowers: handleFindPowers,
            onFindCarPrice: handleFindCarPrice,
            brands,
            models,
            years,
            powers,
            minProductLimit: Number(minProductLimit),
            maxProductLimit: Number(maxProductLimit),
            car,
            isManualCarInput,
            hasUnknownCarNumberError,
            isFoundAllCarData,
            handleCarNumberIsNotReceived,
            isCarNumberNotReceived,
            handleCarNumberIsReceived,
          }),
          [
            isCarNumberSmartComponentShowing,
            handleFindBrands,
            handleFindModels,
            handleFindYears,
            handleFindCar,
            handleFindPowers,
            handleFindCarPrice,
            brands,
            models,
            years,
            powers,
            minProductLimit,
            maxProductLimit,
            car,
            isManualCarInput,
            hasUnknownCarNumberError,
            isFoundAllCarData,
            handleCarNumberIsNotReceived,
            isCarNumberNotReceived,
            handleCarNumberIsReceived,
          ]
        );

        useEffect(() => {
          if (value?.car?.brand?.value) setBrandId(value?.car?.brand?.value);
        }, [value?.car?.brand?.value]);

        useEffect(() => {
          if (value?.car?.model?.value) setModelId(value?.car?.model?.value);
        }, [value?.car?.model?.value]);

        useEffect(() => {
          if (value?.car?.manufactureYear?.value)
            setYearId(value?.car?.manufactureYear?.value);
        }, [value?.car?.manufactureYear?.value]);

        useEffect(() => {
          if (value?.car?.power?.value) setPowerId(value?.car?.power?.value);
        }, [value?.car?.power?.value]);

        useUpdateEffect(() => {
          if (code && brandId && modelId && yearId && powerId && region)
            refetchCarPrice();
        }, [powerId]);

        useUpdateEffect(() => {
          if (!carPriceResponse) return;

          setCar({
            ...car,
            makeId: brandId,
            modelId: modelId,
            power: Number(powerId),
            year: Number(yearId),
            price: carPriceResponse,
          });

          updateFormValue(SmartComponentName.CAR_SEARCH, {
            ...value,
            marketPrice: carPriceResponse?.price ?? minProductLimit,
          });
        }, [carPriceResponse]);

        useUpdateEffect(() => {
          if (CarInsuranceParameters) {
            updateFormValue(SmartComponentName.CAR_INSURANCE_PARAMETERS, {
              ...CarInsuranceParameters,
              period: {
                period: CarInsuranceParameters?.period?.period,
                startDate: undefined,
              },
            });
          }
        }, [value?.haveValidPolicy]);

        const handleChange = useCallback(
          (values: Partial<CarSearchValues>) => {
            onChange(values);
            setHasUnknownCarNumberError(false);
          },
          [onChange]
        );

        const handleAnalyticEventSend =
          useHandleSmartComponentsAnalyticEvents<CarSearchAnalyticEvent>(
            analyticEventsMap
          );

        if (isCarNumberSmartComponentShowing) return;

        return (
          <Suspense fallback={<FallbackSkeleton height={274} />}>
            <CarSearch
              ref={forwardRef}
              value={value}
              onChange={handleChange}
              isSubmitting={isSubmitting}
              options={options}
              onAnalyticEventSend={handleAnalyticEventSend}
            />
          </Suspense>
        );
      }
    )
  )
);
