import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { DriversData } from '@entities/import-smart-components/drivers-data';
import {
  DEFAULT_DRIVER,
  type Driver,
  DriversDataAnalyticEvent,
  type DriversDataOptions,
  type DriversDataValues,
} from '@pulse-smart-components-kit/drivers-data';
import { withMemo } from '@shared/hocs';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import type { SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';
import type { AnalyticEventsMap } from '@pulse-web-platform-core/types';
import type { Ref } from 'react';

import { MIN_AGE_OF_DRIVER, MIN_NUMBER_OF_DRIVERS } from './constants';

const analyticEventsMap: AnalyticEventsMap<DriversDataAnalyticEvent> = {
  [DriversDataAnalyticEvent.ON_DRIVER_SELECTED]: {
    name: analyticEvents.driverSelected,
  },
  [DriversDataAnalyticEvent.ON_DRIVER_ADDED]: {
    name: analyticEvents.driverAdded,
  },
};

const DriversDataWithMemo = withMemo(DriversData);

const DRIVER_ID = uuid();

export const DriversDataAdapter = withMemo(
  observer(
    forwardRef(
      (
        {
          value,
          onChange,
          isSubmitting,
          fieldState,
        }: SmartComponentAdapterProps<DriversDataValues>,
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const {
          MainStore: {
            initProductStore: {
              initState: { extraParams },
            },
            formStateStore: {
              formState: { PolicyholderForm, UserAuthWithPassport },
            },
          },
        } = useStores();

        const hasAuthorized = UserAuthWithPassport?.hasAuthorized;

        const { name, surname, middlename, birthday } = PolicyholderForm ?? {};

        const options: DriversDataOptions = useMemo(() => {
          const minAgeOfDriver = Number(
            extraParams?.find(
              (extraParam) => extraParam.code === MIN_AGE_OF_DRIVER
            )?.value
          );
          const maxDriversNumber = Number(
            extraParams?.find(
              (extraParam) => extraParam.code === MIN_NUMBER_OF_DRIVERS
            )?.value
          );

          const me: Driver = {
            ...DEFAULT_DRIVER,
            name: name || '',
            surname: surname || '',
            middlename: middlename || '',
            birthday: birthday || null,
            id: DRIVER_ID,
          };

          return {
            minAgeOfDriver,
            maxDriversNumber,
            me,
          };
        }, [extraParams, name, surname, middlename, birthday]);

        const handleAnalyticEventSend =
          useHandleSmartComponentsAnalyticEvents<DriversDataAnalyticEvent>(
            analyticEventsMap
          );

        if (!hasAuthorized || !value) {
          return null;
        }

        return (
          <Suspense>
            <DriversDataWithMemo
              ref={forwardRef}
              value={value}
              onChange={onChange}
              isSubmitting={isSubmitting}
              options={options}
              fieldState={fieldState}
              onAnalyticEventSend={handleAnalyticEventSend}
            />
          </Suspense>
        );
      }
    )
  )
);

DriversDataAdapter.displayName = 'DriversDataAdapter';
