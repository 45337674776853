/* eslint-disable @typescript-eslint/no-explicit-any */

import { analyticEvents, sendAnalyticEvent } from '@app/web-analytic';
import { type FlowConfig } from '@product-config/index';
import { action, configure, makeAutoObservable, observable } from 'mobx';

export const analyticEventSteps = [
  analyticEvents.toStepData,
  analyticEvents.toStepRisk,
  analyticEvents.toStepPersonalData,
  analyticEvents.toStepDocs,
];

configure({
  enforceActions: 'always',
});

export class ApplicationStore<T> {
  loading = true; // Отображение лоудера пока получаем данные для инициализации приложения
  disabledSubmit: boolean = true; // Для дизейбла кнопки перехода на следующий экран
  isPaymentStep: boolean = false; // Переменная для отображения компонента оплаты
  retryPayment: boolean = false; // Когда попадаем на страницу ошибки оплаты (/failed-pay), заново пройти оплату
  activeStep: number = 1;
  currentStep: number = 1;
  wantNextStep: boolean = false; // Для перехода на следующий шаг приложения
  deviceType: string = ''; // url параметр приходящий в адресной строке, признак, что пользователь перешёл с мобильного приложения в веб
  phoneFromMobileApp: string = ''; // url параметр приходящий в адресной строке, признак, что пользователь перешёл с мобильного приложения в веб
  flowConfig: Partial<FlowConfig<T>> = {}; // Конфиг приложения.
  isHeaderShowing = true; // Признак отображения хедера.
  isFooterShowing = true; // Признак отображения футера.
  isSmartComponentsShowing = true; // Признак отображения смарт компонентов.
  isSubmitShowing = true; // Признак отображения сабмита.
  isResetFormState = false; // Переопределить состояние формы из стора.
  sendedAnalyticEvents = new Set(); // Отправленные аналитики.
  isRegistration = false;
  isBlockNextStep = false;
  isExternalPayment = true; // Переключение флоу оплаты в отдельную вкладку браузера.

  constructor() {
    makeAutoObservable(this, {
      loading: observable,
      currentStep: observable,
      wantNextStep: observable,
      activeStep: observable,
      isPaymentStep: observable,
      retryPayment: observable,
      flowConfig: observable,
      setFlowConfig: observable,
      isHeaderShowing: observable,
      isFooterShowing: observable,
      isSmartComponentsShowing: observable,
      isSubmitShowing: observable,
      isResetFormState: observable,
      sendedAnalyticEvents: observable,
      isExternalPayment: observable,
      isRegistration: observable,
      sendSingleAnalyticEvent: action,
      setLoading: action,
      setCurrentStep: action,
      setWantNextStep: action,
      setActiveStep: action,
      setIsPaymentStep: action,
      setRetryPayment: action,
      deviceType: observable,
      setDeviceType: action,
      phoneFromMobileApp: observable,
      setPhoneFromMobileApp: action,
      activeStepHasName: action,
      setIsHeaderShowing: action,
      setIsFooterShowing: action,
      setIsSmartComponentsShowing: action,
      setIsSubmitShowing: action,
      setIsResetFormState: action,
      setIsRegistration: action,
    });
  }

  setLoading = (status: boolean) => {
    this.loading = status;
  };

  setCurrentStep = (e: number) => {
    this.currentStep = e;
  };

  setWantNextStep = (e: boolean) => {
    this.wantNextStep = e;
  };

  setActiveStep = (e: number) => {
    this.activeStep = e;
    sendAnalyticEvent(analyticEventSteps[e - 2]);
  };

  activeStepHasName = <T extends string>(e: T): boolean =>
    this.flowConfig.headerConfig?.findIndex((item) => item.name === e) ==
      this.activeStep - 1 || false;

  setFlowConfig = (e: Partial<FlowConfig<T>>) => {
    this.flowConfig = e;
  };

  setIsPaymentStep = (e: boolean) => {
    this.isPaymentStep = e;
  };

  setRetryPayment = (e: boolean) => {
    this.retryPayment = e;
  };

  setDeviceType = (e: string) => {
    this.deviceType = e;
  };

  setPhoneFromMobileApp = (e: string) => {
    this.phoneFromMobileApp = e;
  };

  setDisabledSubmit = (e: boolean) => {
    this.disabledSubmit = e;
  };

  setIsHeaderShowing = (e: boolean) => {
    this.isHeaderShowing = e;
  };

  setIsFooterShowing = (e: boolean) => {
    this.isFooterShowing = e;
  };

  setIsSmartComponentsShowing = (e: boolean) => {
    this.isSmartComponentsShowing = e;
  };

  setIsRegistration = (e: boolean) => {
    this.isRegistration = e;
  };

  setIsSubmitShowing = (e: boolean) => {
    this.isSubmitShowing = e;
  };

  setIsResetFormState = (e: boolean) => {
    this.isResetFormState = e;
  };

  sendSingleAnalyticEvent = (e: string) => {
    if (!this.sendedAnalyticEvents.has(e)) {
      this.sendedAnalyticEvents.add(e);
      sendAnalyticEvent(e);
    }
  };

  setIsBlockNextStep = (e: boolean) => {
    this.isBlockNextStep = e;
  };
}
