import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { Drivers } from '@entities/import-smart-components/drivers';
import { DriversAnalyticEvent } from '@pulse-smart-components-kit/drivers';
import { FallbackSkeleton } from '@shared/components';
import { withMemo } from '@shared/hocs';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { forwardRef, Suspense, useMemo } from 'react';

import type { SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';
import type { DriversValues } from '@pulse-smart-components-kit/drivers';
import type { AnalyticEventsMap } from '@pulse-web-platform-core/types';
import type { Ref } from 'react';

const analyticEventsMap: AnalyticEventsMap<DriversAnalyticEvent> = {
  [DriversAnalyticEvent.ON_MIN_AGE_SELECTED]: {
    name: analyticEvents.minAgeSelected,
  },
  [DriversAnalyticEvent.ON_MIN_EXP_SELECTED]: {
    name: analyticEvents.minExperienceSelected,
  },
};

export const DriversAdapter = withMemo(
  observer(
    forwardRef(
      (
        {
          value,
          onChange,
          isSubmitting,
        }: SmartComponentAdapterProps<Partial<DriversValues>>,
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const {
          MainStore: {
            initProductStore: {
              initState: { extraParams },
            },
            productStore: { isCarNumberSmartComponentShowing },
          },
        } = useStores();

        const options = useMemo(() => {
          const minAgeOfDriver = Number(
            extraParams?.find(
              (extraParam) => extraParam.code === 'minAgeOfDriver'
            )?.value
          );
          const minExperienceOfDriver = Number(
            extraParams?.find(
              (extraParam) => extraParam.code === 'minExperienceOfDriver'
            )?.value
          );

          return {
            minAgeOfDriver,
            minExperienceOfDriver,
          };
        }, [extraParams]);

        const handleAnalyticEventSend =
          useHandleSmartComponentsAnalyticEvents<DriversAnalyticEvent>(
            analyticEventsMap
          );

        if (isCarNumberSmartComponentShowing) return;

        return (
          <Suspense fallback={<FallbackSkeleton height={274} />}>
            <Drivers
              ref={forwardRef}
              isSubmitting={isSubmitting}
              value={value}
              onChange={onChange}
              options={options}
              onAnalyticEventSend={handleAnalyticEventSend}
            />
          </Suspense>
        );
      }
    )
  )
);

DriversAdapter.displayName = 'DriversAdapter';
