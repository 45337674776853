import { QUERY_KEYS } from '@pulse-web-platform-core/constants';
import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

import type { Passport, ResponseError } from '@pulse-web-platform-core/types';

export interface RegistrationRequestData {
  user: {
    firstName: string;
    lastName: string;
    middleName: string;
    passport: Passport;
    extendedIntermediaryChannelCode: string;
    clientCategory: string;
    utmSource: string;
    utmMedium: string;
    utmCampaign: string;
    utmContent: string;
    wmId: string;
    birthDay: string;
    email: string;
    userPolicies: { approvedAt: Date; code: string }[];
  };
}

interface RegistrationParams {
  data: RegistrationRequestData;
  accessToken?: string;
}

interface RegistrationResponse {
  authorization: {
    accessToken: string;
    csrfToken: string;
  };
  isVip: boolean;
}

export const useQueryRegistration = ({
  data,
  accessToken,
}: RegistrationParams) => {
  return useQuery<RegistrationResponse, ResponseError>(
    [QUERY_KEYS.registration],
    fetchData<RegistrationResponse>({
      url: Endpoints.REG,
      method: 'POST',
      data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
    { enabled: false }
  );
};
