import { differenceInYears } from 'date-fns';

import type { Driver } from '@pulse-smart-components-kit/drivers-data';

const TODAY = new Date();

export const getMinAge = (drivers: Driver[] = []) => {
  const ages = drivers
    .filter((driver) => driver.birthday)
    .map((driver) => differenceInYears(TODAY, driver.birthday || TODAY));

  if (!ages.length) {
    return null;
  }

  return Math.min(...ages);
};
