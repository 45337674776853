import { ErrorCode } from '@pulse-web-platform-core/constants';
import { hasError } from '@pulse-web-platform-core/utils';
import { useStores } from '@shared/hooks';
import {
  useQueryConfirmAuthentication,
  type AuthenticationResponse,
} from '@shared/queries';
import { useEffect } from 'react';

export const useHandleConfirmAuthentication = (
  isLoadingAuthentication?: boolean,
  resAuthentication?: AuthenticationResponse
) => {
  const {
    MainStore: {
      errorStore: { setErrorRetry },
      authStore: {
        callOtp,
        authenticationToken,
        profile,
        confirmationId,
        setDisableForm,
        setOtpIsLoading,
        setOtpErrorCode,
        handleOtpResponse,
      },
    },
  } = useStores();

  const {
    isLoading,
    error,
    data: res,
    refetch,
    remove,
  } = useQueryConfirmAuthentication({
    code: callOtp,
    confirmationId: resAuthentication?.confirmationId,
    authToken: authenticationToken,
  });

  useEffect(() => {
    const shouldRefetchOtp =
      callOtp &&
      !profile &&
      confirmationId &&
      resAuthentication &&
      authenticationToken &&
      !isLoadingAuthentication;

    if (shouldRefetchOtp) {
      setDisableForm(true);
      refetch();
    }
  }, [
    profile,
    callOtp,
    confirmationId,
    resAuthentication,
    authenticationToken,
    isLoadingAuthentication,
  ]);

  useEffect(() => {
    setOtpIsLoading(isLoading);

    if (!isLoading && res && res.accessToken) {
      handleOtpResponse(res);
      setOtpErrorCode(false);
      setErrorRetry(false);
    }

    const isUnknownUserOrCode =
      !isLoading && hasError(error, ErrorCode.UNKNOWN_USER_OR_CODE);

    if (isUnknownUserOrCode) {
      setOtpErrorCode(true);
    }
  }, [isLoading, error, res]);

  return {
    isLoadingConfirmAuthentication: isLoading,
    errorConfirmAuthentication: error,
    resConfirmAuthentication: res,
    refetchConfirmAuthentication: refetch,
    removeConfirmAuthentication: remove,
  };
};
