import {
  AddressType,
  DATE_FORMAT,
  PASSPORT_CARD_TYPE,
} from '@pulse-web-platform-core/constants';
import { PASSPORT_REG_EXP } from '@pulse-web-platform-core/regexp';
import { parsingDocument } from '@shared/utils';
import { format } from 'date-fns';

import type { UpdateProfileRequest } from '../types';
import type { PolicyholderFormValues } from '@pulse-smart-components-kit/policyholder-form';
import type { UpdateProfileResponse } from '@pulse-web-platform-core/types';

export const getUpdatedProfile = (
  client?: PolicyholderFormValues,
  profile?: UpdateProfileResponse['profile']
): UpdateProfileRequest | undefined => {
  if (!profile || !client) {
    return undefined;
  }

  const { email: clientEmail } = client;
  const { email: profileEmail, passport: profilePassport } = profile;

  const updatedEmail = clientEmail !== profileEmail ? clientEmail : undefined;

  if (!profilePassport) {
    const {
      passport: clientPassport,
      passportDate,
      passportCode,
      passportByWho,
      address,
    } = client;

    const [series, number] = parsingDocument(
      clientPassport ?? '',
      PASSPORT_REG_EXP
    );

    return {
      clientChange: {
        email: updatedEmail,
        passport: {
          cardType: PASSPORT_CARD_TYPE,
          cardSeries: series.replace(' ', ''),
          cardNumber: number.replace(' ', ''),
          cardIssueDate: format(passportDate ?? new Date(), DATE_FORMAT),
          cardIssuerCode: passportCode?.replace('-', ''),
          cardIssuerName: passportByWho,
          addresses: [
            {
              addressType: AddressType.REGISTRATION,
              address: address?.value ?? '',
              addressCode: address?.fias_id,
            },
          ],
        },
      },
    };
  }

  if (!profilePassport.addresses[0]?.addressCode) {
    const { address: addressClient } = client;

    return {
      clientChange: {
        email: updatedEmail,
        passport: {
          cardType: profilePassport?.cardType ?? '',
          cardSeries: profilePassport?.cardSeries ?? '',
          cardNumber: profilePassport?.cardNumber ?? '',
          cardIssueDate: profilePassport?.cardIssueDate ?? '',
          cardIssuerCode: profilePassport?.cardIssuerCode ?? '',
          cardIssuerName: profilePassport?.cardIssuerName ?? '',
          addresses: [
            {
              addressType: AddressType.REGISTRATION,
              addressCode: addressClient?.fias_id,
              address: addressClient?.value ?? '',
            },
          ],
        },
      },
    };
  }

  if (updatedEmail) {
    return {
      clientChange: {
        email: updatedEmail,
        passport: {
          cardType: profilePassport?.cardType ?? '',
          cardSeries: profilePassport?.cardSeries ?? '',
          cardNumber: profilePassport?.cardNumber ?? '',
          cardIssueDate: profilePassport?.cardIssueDate ?? '',
          cardIssuerCode: profilePassport?.cardIssuerCode ?? '',
          cardIssuerName: profilePassport?.cardIssuerName ?? '',
          addresses: [...(profilePassport?.addresses ?? [])],
        },
      },
    };
  }

  return undefined;
};
