import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

import type {
  ContractDataType,
  ResponseError,
} from '@pulse-web-platform-core/types';
import type { InitOrderResponse } from '@shared/types';

interface InitOrderParams {
  data: ContractDataType;
  accessToken?: string;
}

export const useQueryInitOrder = ({ data, accessToken }: InitOrderParams) => {
  return useQuery<InitOrderResponse, ResponseError>(
    ['initOrder', data, accessToken],
    fetchData<InitOrderResponse>({
      url: Endpoints.INIT_ORDER,
      method: 'POST',
      data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
    { enabled: false }
  );
};
