import { SmartComponentName } from '@product-config/constants';
import { DEFAULT_OPTION } from '@pulse-smart-components-kit/common';
import { useCallback } from 'react';

import { useStores } from './use-stores';

export const useHandleManualInput = (carNumber: string | undefined) => {
  const {
    MainStore: {
      initProductStore: {
        initState: { minProductLimit },
      },
      productStore: { setIsManualCarInput },
      formStateStore: { updateFormValue },
    },
  } = useStores();

  return useCallback(() => {
    setIsManualCarInput(true);

    updateFormValue(SmartComponentName.CAR_SEARCH, {
      carNumber,
      marketPrice: minProductLimit,
      car: {
        model: DEFAULT_OPTION,
        power: DEFAULT_OPTION,
        brand: DEFAULT_OPTION,
        manufactureYear: DEFAULT_OPTION,
      },
      isManualVINInput: true,
      vin: '',
      mileage: null,
    });
  }, [carNumber, minProductLimit]);
};
