import { SmartComponentName } from '@product-config/constants';
import {
  ErrorCode,
  QUERY_KEYS,
  PublicRoutes,
} from '@pulse-web-platform-core/constants';
import { Endpoints } from '@shared/constants';
import { useStores } from '@shared/hooks';
import { OPERATION_ID } from '@shared/operations';
import { fetchData } from '@shared/utils';
import { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { assignRef, useCallbackRef } from 'use-callback-ref';

import type {
  CompletePaymentRequest,
  CompletePaymentResponse,
} from './complete-payment.types';
import type { PaymentStage } from '@shared/types';

export const useCompletePayment = (data: CompletePaymentRequest) => {
  const {
    MainStore: {
      errorStore: { setErrorRetry },
      formStateStore: {
        formState: { CheckPolicy, B2P },
        updateFormValue,
      },
      initProductStore: { initOrder, initOrderError },
    },
  } = useStores();
  const [tick, setTick] = useState(0);
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const completePaymentData = { ...data };

  const {
    data: res,
    isLoading,
    refetch,
  } = useQuery(
    [QUERY_KEYS.completePayment, completePaymentData],
    fetchData<CompletePaymentResponse>({
      url: Endpoints.COMPLETE_PAYMENT,
      method: 'POST',
      data: completePaymentData,
    }),
    { enabled: false }
  );

  const {
    isLoading: isLoadingPaymentStage,
    data: resPaymentStage,
    refetch: refetchPaymentStage,
    error: errorPaymentStage,
  } = useQuery(
    [
      `payment-stage-polling-${tick}`,
      completePaymentData.id,
      tick,
      B2P?.showInitOrder,
    ],
    fetchData<PaymentStage>({
      url: `${Endpoints.PAYMENT_STAGE}/${completePaymentData.id}`,
      method: 'GET',
      data: {
        operationId: completePaymentData.id,
      },
    }),
    { enabled: Boolean(completePaymentData.id && B2P?.showInitOrder) }
  );

  const onRefPaymentSuccessFailUpdate = useCallback((newValue: any) => {
    if (newValue && newValue !== null) {
      const parsedValue = JSON.parse(newValue);
      if (parsedValue === false) {
        updateFormValue(SmartComponentName.CHECK_POLICY, {
          ...CheckPolicy,
          acceptRequirements: false,
        });
        updateFormValue(SmartComponentName.B2P, {
          initOrder: undefined,
          showInitOrder: false,
          isValid: true,
        });
        navigate(PublicRoutes.FAILED_PAY);
      }
    }
  }, []);

  const onRefOperationIdUpdate = useCallback(
    (newValue: any) => {
      if (newValue && newValue !== null) {
        completePaymentData.operationId = newValue;

        if (
          !!completePaymentData?.id &&
          resPaymentStage?.status === 'COMPLETE'
        ) {
          refetch().then((res) => {
            const isCompleted =
              res.data?.operationStatus === OPERATION_ID.COMPLETE;

            isCompleted
              ? navigate(PublicRoutes.SUCCESS_PAY)
              : navigate(PublicRoutes.IN_PROGRESS_PAY);
          });
        }
      }
    },
    [completePaymentData, resPaymentStage]
  );

  const refPaymentDeclineId = useCallbackRef<null | string>(
    null,
    onRefPaymentSuccessFailUpdate
  );

  const refSOperationId = useCallbackRef<null | string>(
    null,
    onRefOperationIdUpdate
  );

  useEffect(() => {
    if (completePaymentData.id && B2P?.showInitOrder) {
      refetchPaymentStage();
    }
  }, [completePaymentData.id, tick, B2P?.showInitOrder]);

  const [timerId, setTimerId] = useState<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (!isLoadingPaymentStage && (resPaymentStage || errorPaymentStage)) {
      if (
        !timerId &&
        (resPaymentStage?.status === 'PROCESSING' || errorPaymentStage)
      ) {
        const tickTimerId = setInterval(() => {
          setTick((prevTick) => prevTick + 1);
          queryClient.removeQueries(`payment-stage-polling-${tick}`); // сброс кэша всех запросов для правильного перезапуска приложения
        }, 2000);

        setTimerId(tickTimerId);
        const time = Number(initOrder?.timeout) * 1000 || 0;

        setTimeout(() => {
          clearInterval(tickTimerId);
          setTimerId(tickTimerId);
        }, time);
      }

      if (
        resPaymentStage?.status === 'COMPLETE' &&
        refSOperationId.current !== resPaymentStage.operationId
      ) {
        assignRef(refSOperationId, String(resPaymentStage.operationId));
      }
      if (resPaymentStage?.status === 'FAILED') {
        assignRef(refPaymentDeclineId, 'false');
      }
    } else if (!isLoadingPaymentStage && !resPaymentStage) {
      clearInterval(timerId);
      setTimerId(undefined);
    }

    if (initOrderError?.code === ErrorCode.BUSINESS_ERROR) {
      navigate(PublicRoutes.SUCCESS_PAY);
    }
  }, [
    isLoadingPaymentStage,
    resPaymentStage,
    initOrder,
    errorPaymentStage,
    initOrderError,
  ]);

  useEffect(() => {
    if (!isLoading && res) {
      setErrorRetry(false);
      assignRef(refSOperationId, undefined);
    }
  }, [isLoading, res]);

  return refetch;
};
