import { isAppleSafari, scrollToIframe } from '@pulse-web-platform-core/utils';
import { DocumentPolicy, MessagePolicy, Ruble } from '@pulse-web-ui/icons';
import { observer } from 'mobx-react-lite';
import { lazy, Suspense, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ProgressPaymentComponent = lazy(() =>
  import('@pulse-smart-components-kit/payment/lib/progress-payment').then(
    (module) => ({
      default: module.ProgressPayment,
    })
  )
);

export const InProgressPay = observer(() => {
  const { t } = useTranslation();

  const cards = [
    {
      id: 1,
      icon: <Ruble width={64} />,
      description: t('COMMON:pay.description-1'),
    },
    {
      id: 2,
      icon: <MessagePolicy width={64} />,
      description: t('COMMON:pay.description-2'),
    },
    {
      id: 3,
      icon: <DocumentPolicy width={64} />,
      description: t('COMMON:pay.description-3'),
    },
  ];

  const handleMobileAppClickForBrowser = useCallback(() => {
    const deepLink = window.envUrls.INSURANCE_MOBILE_APP_HOT_LINK;

    isAppleSafari()
      ? (window.location.href = deepLink)
      : window.open(deepLink, '_blank');
  }, []);

  useEffect(() => {
    scrollToIframe();
  }, []);

  return (
    <Suspense fallback="">
      <ProgressPaymentComponent
        onClick={handleMobileAppClickForBrowser}
        cards={cards}
      />
    </Suspense>
  );
});
