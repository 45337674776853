import { QUERY_KEYS } from '@pulse-web-platform-core/constants';
import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

import type {
  ResponseError,
  UpdateProfileResponse,
} from '@pulse-web-platform-core/types';

export const useQueryGetProfile = (accessToken: string | undefined) => {
  return useQuery<UpdateProfileResponse, ResponseError>(
    [QUERY_KEYS.getProfile],
    fetchData<UpdateProfileResponse>({
      url: Endpoints.GET_PROFILE,
      method: 'POST',
      data: {},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
    { enabled: false }
  );
};
