import { PolicyholderData } from '@entities/import-smart-components/policyholder-data';
import { SmartComponentName } from '@product-config/constants';
import { type SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';
import { useIsDesktop } from '@pulse-web-platform-core/hooks';
import {
  getFormattedPassportCode,
  getRuFormattedDate,
  phoneDisplayValueExtCasting,
} from '@pulse-web-platform-core/utils';
import { FallbackSkeleton } from '@shared/components';
import { withMemo } from '@shared/hocs';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, useEffect, useMemo, type Ref } from 'react';

import type {
  PolicyholderDataOptions,
  PolicyholderDataValues,
} from '@pulse-smart-components-kit/policyholder-data';

export const PolicyholderDataAdapter = withMemo(
  observer(
    forwardRef(
      (
        {
          value,
          onChange,
          isSubmitting,
          fieldState,
        }: SmartComponentAdapterProps<PolicyholderDataValues>,
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const isDesktop = useIsDesktop();
        const {
          MainStore: {
            authStore: { disableForm },
            formStateStore: {
              formState: { UserAuthWithPassport },
              updateFormValue,
            },
            applicationStore: { isRegistration },
          },
        } = useStores();

        const options: PolicyholderDataOptions = useMemo(
          () => ({
            middlename: UserAuthWithPassport?.middlename ?? '',
            name: UserAuthWithPassport?.name,
            surname: UserAuthWithPassport?.surname,
            birthday: !!UserAuthWithPassport?.birthday
              ? getRuFormattedDate(UserAuthWithPassport?.birthday)
              : undefined,
            phone: !!UserAuthWithPassport?.phone
              ? phoneDisplayValueExtCasting(UserAuthWithPassport?.phone)
              : undefined,
            email: UserAuthWithPassport?.email,
            address: UserAuthWithPassport?.address?.value,
            passport: UserAuthWithPassport?.passport,
            passportCode: !!UserAuthWithPassport?.passportCode
              ? getFormattedPassportCode(UserAuthWithPassport?.passportCode)
              : undefined,
            passportByWho: UserAuthWithPassport?.passportByWho,
            passportDate: !!UserAuthWithPassport?.passportDate
              ? getRuFormattedDate(UserAuthWithPassport?.passportDate)
              : undefined,
          }),
          [UserAuthWithPassport]
        );

        const hasAuthorized = UserAuthWithPassport?.hasAuthorized;

        useEffect(() => {
          if (!isRegistration) {
            updateFormValue(SmartComponentName.POLICYHOLDER_DATA, {
              isValid: true,
            });
          }
        }, [isRegistration]);

        if (!hasAuthorized || !isRegistration) {
          return null;
        }

        return (
          <Suspense
            fallback={<FallbackSkeleton height={isDesktop ? 260 : 370} />}
          >
            <PolicyholderData
              ref={forwardRef}
              value={value}
              onChange={onChange}
              isSubmitting={isSubmitting}
              options={options}
              disabled={disableForm}
              fieldState={fieldState}
            />
          </Suspense>
        );
      }
    )
  )
);

PolicyholderDataAdapter.displayName = 'PolicyholderDataAdapter';
