export const smartHeaderNS = {
  SmartHeader: {
    labels: {
      calculation: 'Авто',
      options: 'Параметры',
      formalization: 'Риски',
      payment: 'Оплата',
      clients: 'Персональные данные',
    },
  },
};
