import { withMemo } from '@shared/hocs';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';

import type { FormBuilderStructure } from '@shared/types';

interface Props {
  components: FormBuilderStructure;
}

export const FormBuilderHeader = withMemo(
  observer(({ components }: Props) => {
    const {
      MainStore: {
        applicationStore: { isHeaderShowing },
      },
    } = useStores();

    if (!isHeaderShowing) return;

    return components.header.map(
      ({ component: Component, props: { name } }, index) => (
        <Component key={`${name}-${index}`} />
      )
    );
  })
);

FormBuilderHeader.displayName = 'FormBuilderHeader';
