import { SmartComponentName } from '@product-config/constants';
import {
  ErrorCode,
  REGISTRATION,
  REGISTRATION_ERROR_TEXT,
} from '@pulse-web-platform-core/constants';
import { hasError } from '@pulse-web-platform-core/utils';
import { useStores } from '@shared/hooks';
import { useQueryRegistration } from '@shared/queries';
import { useEffect } from 'react';

import type { RegistrationRequestData } from '@shared/queries';
import type {
  FormBuilderUpdateErrorHandler,
  FormBuilderUpdateHandler,
} from '@shared/types';
import type { AxiosError } from 'axios';

export const useHandleRegistration = (
  registrationData: RegistrationRequestData,
  updateFormValue: FormBuilderUpdateHandler,
  setFormValueError: FormBuilderUpdateErrorHandler
) => {
  const {
    MainStore: {
      errorStore: { setErrorRetry, setRefetch, setHasError },
      authStore: {
        authTokens,
        accessToken,
        handleOtp,
        handleAuth,
        setUseOtp,
        setAuthTokens,
        setDisableForm,
        setExternalAuthPhone,
      },
    },
  } = useStores();

  const {
    isLoading,
    error,
    data: res,
    refetch,
    remove,
  } = useQueryRegistration({
    data: registrationData,
    accessToken,
  });

  useEffect(() => {
    if (!isLoading && res && authTokens) {
      setDisableForm(false);
      setErrorRetry(false);

      const updatedAuthTokens = { ...authTokens };
      const csrfToken = res?.authorization?.csrfToken;

      updatedAuthTokens.authorization!.accessToken =
        res?.authorization?.accessToken;
      updatedAuthTokens.authorization!.isVip = res.isVip;
      updatedAuthTokens.authorization!.csrfToken = csrfToken;

      setAuthTokens(updatedAuthTokens);

      localStorage.removeItem('csrfToken');

      if (csrfToken) {
        localStorage.setItem('csrfToken', csrfToken);
      }
    }

    if (!isLoading && error) {
      setAuthTokens(undefined);
      setDisableForm(false);
      handleOtp('', false);
      setUseOtp(true);
      setExternalAuthPhone(undefined);
      handleAuth('');

      if (hasError(error, ErrorCode.VALIDATION_ERROR)) {
        const regDescription = (error as AxiosError<any>).response?.data
          ?.description;

        const regFields = Object.values(REGISTRATION);
        const regKeys = Object.keys(REGISTRATION);

        if (regDescription) {
          regKeys.forEach((field, index) => {
            if (regDescription?.includes(field)) {
              setFormValueError(
                `${SmartComponentName.USER_AUTH_WITH_PASSPORT}.${regFields[index]}`,
                REGISTRATION_ERROR_TEXT[
                  field as keyof typeof REGISTRATION_ERROR_TEXT
                ]
              );
            }
          });
        }

        updateFormValue(
          `${SmartComponentName.USER_AUTH_WITH_PASSPORT}.client`,
          false
        );
        remove();
      } else if (
        error.response?.status === 400 &&
        error.response?.data?.code === ErrorCode.TECHNICAL_ERROR
      ) {
        setHasError(true);
        setRefetch(refetch);
      }
    }
  }, [isLoading, error, res]);

  return {
    isLoadingRegistration: isLoading,
    errorRegistration: error,
    resRegistration: res,
    refetchRegistration: refetch,
    removeRegistration: remove,
  };
};
