import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

import type { RegionResponse } from './types';

interface Params {
  carNumber?: string;
  code?: string;
}

export const useQueryRegion = ({ carNumber, code }: Params) => {
  const carNumberWithoutMask = carNumber?.replaceAll(/[_\s]/g, '');
  const enabled = Boolean(code && carNumber);

  return useQuery(
    ['get-region', carNumber, code],
    fetchData<RegionResponse>({
      url: `${Endpoints.GET_REGION}?regNum=${carNumberWithoutMask}&productVersion=${code}`,
      method: 'GET',
    }),
    { enabled }
  );
};
