import { useStores } from '@shared/hooks';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useHandleQueries = () => {
  const utmPayload: Record<string, any> = {};
  const {
    MainStore: {
      initProductStore: { setRegUtm },
    },
  } = useStores();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('utm_source')) {
      utmPayload.utm_source = searchParams.get('utm_source');
    }

    if (searchParams.get('utm_campaign')) {
      utmPayload.utm_campaign = searchParams.get('utm_campaign');
    }

    if (searchParams.get('utm_content')) {
      utmPayload.utm_content = searchParams.get('utm_content');
    }

    if (searchParams.get('utm_medium')) {
      utmPayload.utm_medium = searchParams.get('utm_medium');
    }

    if (searchParams.get('utm_media_source')) {
      utmPayload.utm_media_source = searchParams.get('utm_media_source');
    }
    if (searchParams.get('campaign')) {
      utmPayload.campaign = searchParams.get('campaign');
    }
    if (searchParams.get('media_source')) {
      utmPayload.media_source = searchParams.get('media_source');
    }
    if (searchParams.get('wm_id')) {
      utmPayload.wm_id = searchParams.get('wm_id');
    }

    setRegUtm(utmPayload);
  }, []);
};
