import { media, styled } from '@pulse-web-ui/theme';

import type { FallbackSkeletonProps } from './fallback-skeleton.types';

export const Wrapper = styled.div<FallbackSkeletonProps>`
  display: flex;
  flex-direction: column;
  ${({ transparent }) => (!transparent ? 'background: #fff;' : undefined)}
  ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : undefined)}
  min-height: ${({ height }) => height}px;
  padding: 20px 16px;
  border-radius: 24px;
  gap: 16px;

  align-items: center;
  justify-content: center;

  & [data-orientation='horizontal'] {
    height: 2px !important;
  }

  & [data-reach-slider-track] {
    top: -6px;
  }

  ${media.desktop} {
    padding: 24px;
    gap: 24px;
  }
`;
