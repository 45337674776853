import i18n from '@app/i18n/config';
import {
  declineWord,
  formattingPeriod,
  formattingPrice,
} from '@pulse-web-platform-core/utils';
import { useStores } from '@shared/hooks';
import { useMemo } from 'react';

import { formattingFranchise, getMinAge, getMinExperienceAge } from '../utils';

const DECLINATION = {
  one: i18n.t('COMMON:declensions.year-1'),
  few: i18n.t('COMMON:declensions.year-2'),
  many: i18n.t('COMMON:declensions.year-3'),
};

export const useGetItems = () => {
  const {
    MainStore: {
      formStateStore: {
        formState: { CarSearch, Drivers, CarInsuranceParameters, DriversData },
      },
    },
  } = useStores();

  const marketPrice = CarSearch?.marketPrice;

  const minDriverAgeFromDrivers = useMemo(
    () => getMinAge(DriversData?.drivers),
    [DriversData?.drivers]
  );

  const minDriverExperienceFromDrivers = useMemo(
    () => getMinExperienceAge(DriversData?.drivers),
    [DriversData?.drivers]
  );

  const minDriverAge = minDriverAgeFromDrivers ?? Drivers?.minDriverAge;
  const minDriverExperience =
    minDriverExperienceFromDrivers ?? Drivers?.minDriverExperience;
  const period = CarInsuranceParameters?.period?.period ?? '';
  const franchise = CarInsuranceParameters?.franchise ?? '';

  return [
    {
      title: i18n.t('COMMON:labels.compensation'),
      value: formattingPrice(Number(marketPrice)),
    },
    {
      title: i18n.t('COMMON:labels.ageAndExperience'),
      value: `${declineWord(Number(minDriverAge), DECLINATION)} · ${declineWord(Number(minDriverExperience), DECLINATION)}`,
    },
    {
      title: i18n.t('COMMON:labels.period'),
      value: formattingPeriod(period),
    },
    {
      title: i18n.t('COMMON:labels.franchise'),
      value: formattingFranchise(franchise),
    },
  ];
};
