import { object, string, array, boolean, number } from 'yup';

import { OBJECT_TYPE } from './constants';

const risk = object({
  code: string().required(),
  amount: number().required(),
});

const baseParameters = object({
  effectiveSince: string().required(),
  productCode: string().required(),
  contractDuration: string().required(),
  risksObjects: array(risk).min(1),
});

const passport = object({
  cardSeries: string().required(),
  cardNumber: string().required(),
  cardIssueDate: string().required(),
  cardIssuerCode: string().required(),
  cardIssuerName: string().required(),
});

const address = object({
  address: string().required(),
  addressCode: string().required(),
});

export const vehicleOwner = object({
  lastName: string().required(),
  firstName: string().required(),
  middleName: string(),
  birthDate: string().required(),
  passport: passport.required(),
  address: address.required(),
});

const drivingLicense = object({
  licenseSeries: string(),
  licenseNumber: string().required(),
  licenseIssueDate: string().required(),
  drivingStartDate: number().required(), // На вход приходит только год, который затем преобразовывается в дату
});

const vehicleDriver = object({
  lastName: string().required(),
  firstName: string().required(),
  middleName: string().optional(),
  birthDate: string().required(),
  drivingLicense: drivingLicense.required(),
});

const vehicleDrivers = array(vehicleDriver);

const vehicleDocument = object({
  documentType: string().required(),
  vehicleDocSeries: string(),
  vehicleDocNumber: string().required(),
  vehicleDocIssueDate: string().required(),
});

const franchise = object({
  franchiseType: string().required(),
  franchiseValue: number().required(),
});

export const insuranceObject = object({
  objectType: string().oneOf([OBJECT_TYPE]).required(),
  licensePlate: string(),
  vin: string().required(),
  vehicleType: string(),
  make: string().required(),
  makeId: string().required(),
  model: string().required(),
  modelId: string().required(),
  year: number().required(),
  enginePower: number().required(),
  bodyType: string(),
  doors: number(),
  engine: string(),
  transmissionType: string(),
  modification: string(),
  modificationId: string(),
  mileage: number().required(),
  price: number().required(),
  region: string().required(),
  isCredit: boolean().required(),
  stoType: string().required(),
  inspection: boolean(),
  inspectionResult: boolean(),
  vehicleDocument: vehicleDocument.required(),
  franchise: franchise.nullable(),
});

const productProperty = object({
  kind: string().required(),
  hasActiveKasko: boolean().required(),
  insuranceSum: number().required(),
  vehicleOwner: vehicleOwner.required(),
  vehicleDrivers: vehicleDrivers.min(1).required(),
  insuranceObject: insuranceObject.required(),
});

export const requestBody = object({
  baseParameters: baseParameters.required(),
  productProperty: productProperty.required(),
});
