import type { PolicyImprovementValues } from '@pulse-smart-components-kit/policy-improvement';

import { DEFAULT_RISK_CODE } from '../../entities/adapters/footer-adapter/constants';

interface Params {
  insuranceRisks?: PolicyImprovementValues;
  amount: number;
}

export const getRisks = ({ insuranceRisks, amount }: Params) => {
  if (!insuranceRisks?.improvementItems?.length) {
    return [
      {
        code: DEFAULT_RISK_CODE,
        amount,
      },
    ];
  }

  return insuranceRisks.improvementItems.map((item) => ({
    code: item.code,
    amount,
  }));
};
