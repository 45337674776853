import { Endpoints } from '@shared/constants';
import { axiosInstance } from '@shared/utils';
import { useCallback } from 'react';

import type {
  GetDadataSuggestionsRequestData,
  GetDadataSuggestionsResponse,
} from '@shared/queries';

export const useGetDadataRequest = () => {
  return useCallback((data: GetDadataSuggestionsRequestData) => {
    return axiosInstance<GetDadataSuggestionsResponse>({
      url: `${Endpoints.DADATA}/suggest/address`,
      method: 'POST',
      data,
    });
  }, []);
};
