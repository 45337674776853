import { memo } from 'react';

import type { ReactNode } from 'react';

export function withMemo<Props>(Component: (props: Props) => ReactNode) {
  return memo(Component, (prevProps, newProps) => {
    const arePropsEqual =
      Object.keys(prevProps).length === Object.keys(newProps).length &&
      JSON.stringify(prevProps) === JSON.stringify(newProps);

    return arePropsEqual;
  });
}
