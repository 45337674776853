/* eslint-disable indent */
import { analyticEvents, sendAnalyticEvent } from '@app/web-analytic';
import { Submit } from '@entities/import-smart-components/submit';
import {
  FormBuilderEventBus,
  FormBuilderAction,
} from '@features/form-builder/utils';
import { SmartComponentName } from '@product-config/constants';
import {
  getFormInsuranceContactsSchema,
  getPhoneValidationSchema,
} from '@pulse-smart-components-kit/common';
import { CurrencyLabel } from '@pulse-web-platform-core/constants';
import { numFormat } from '@pulse-web-platform-core/utils';
import { useStores } from '@shared/hooks';
import { getPurchaseRoute } from '@shared/utils';
import { observer } from 'mobx-react-lite';
import { Suspense, useMemo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
export { numFormat } from '@pulse-web-platform-core/utils';

import type { FC } from 'react';

export const SubmitAdapter: FC = observer(() => {
  const { t } = useTranslation();
  const {
    MainStore: {
      applicationStore: {
        currentStep,
        disabledSubmit,
        isExternalPayment,
        flowConfig: { otpStep, maxSteps },
        activeStepHasName,
      },
      authStore: {
        setExternalAuthPhone,
        hasAuthorized,
        hasPhone,
        handleOtp,
        externalAuthPhone,
        setDisableForm,
        otpIsLoading,
        authIsLoading,
        disableForm,
        otpErrorCode,
        setOtpErrorCode,
        authError,
        useOtp,
        setRefetchAuthentication,
      },
      productStore: {
        setPulseClient,
        pulseClient,
        price,
        hasPricesCalcErr,
        orderData,
        setPrice,
        agentLogin,
        clearPromoCode,
        isCarNumberSmartComponentShowing,
      },
      formStateStore: {
        formState: { B2P, CheckPolicy, UserAuthWithPassport },
        formString,
        updateFormValue,
      },
    },
  } = useStores();

  const [route, setRoute] = useState<string>();

  const isPaymentChecked =
    currentStep === maxSteps && !CheckPolicy?.acceptRequirements;

  const buttonText = useMemo(() => {
    const paymentVal = numFormat(Number(orderData?.premiumAndDelta || ''));
    const currency = CurrencyLabel.RUB;

    switch (currentStep) {
      case maxSteps:
        return t('COMMON:labels.getPayment', {
          paymentVal,
          currency,
        });
      case otpStep:
        return hasAuthorized ? undefined : t('COMMON:labels.submitGetCode');
      default:
        return undefined;
    }
  }, [currentStep, hasAuthorized, orderData?.premiumAndDelta]);

  // генерация урла для открытия в новой вкладке Б2П
  useEffect(() => {
    if (currentStep === maxSteps && B2P?.initOrder) {
      setRoute(B2P ? getPurchaseRoute(B2P) : undefined);
    }
    if (currentStep !== maxSteps) {
      setRoute(undefined);
    }
  }, [currentStep, maxSteps, B2P]);

  const handleSubmit = () => {
    if (currentStep === maxSteps) {
      updateFormValue(SmartComponentName.B2P, {
        ...B2P,
        ...{ showInitOrder: true, isExternalPayment },
      });

      if (CheckPolicy?.acceptRequirements) {
        updateFormValue(SmartComponentName.CHECK_POLICY, {
          ...CheckPolicy,
          acceptRequirements: false,
          isValid: false,
        });
      }

      sendAnalyticEvent(analyticEvents.toStepPay);
    }

    FormBuilderEventBus.publish<null>(FormBuilderAction.SUBMIT_FORM, null);
  };

  const authUserHandler = useCallback((phone: string) => {
    FormBuilderEventBus.publish(FormBuilderAction.AUTH_USER, {
      phone,
    });
  }, []);

  useEffect(() => {
    if (UserAuthWithPassport) {
      setPulseClient(UserAuthWithPassport?.client || false);
    }

    if (
      !UserAuthWithPassport?.client &&
      getFormInsuranceContactsSchema(t, agentLogin).isValidSync(
        UserAuthWithPassport
      )
    ) {
      setExternalAuthPhone(UserAuthWithPassport?.phone);
    } else if (
      UserAuthWithPassport?.client &&
      getPhoneValidationSchema(t, agentLogin).isValidSync(
        UserAuthWithPassport?.phone
      )
    ) {
      setExternalAuthPhone(String(UserAuthWithPassport?.phone));
    } else {
      setExternalAuthPhone(undefined);
    }
  }, [formString]);

  const shouldHideSubmit = useMemo(() => {
    const isAgentFlow = !!agentLogin;
    const isPaymentStep = currentStep === maxSteps;

    return isPaymentStep && isAgentFlow;
  }, [currentStep, maxSteps, agentLogin]);

  return (
    <Suspense>
      <Submit
        buttonText={buttonText}
        onSubmit={handleSubmit}
        hasAuthorized={hasAuthorized}
        useOtp={useOtp && currentStep === otpStep}
        hasPhone={hasPhone}
        handleOtp={handleOtp}
        handleAuth={authUserHandler}
        externalPhone={externalAuthPhone}
        setDisableForm={setDisableForm}
        otpIsLoading={otpIsLoading}
        authIsLoading={authIsLoading}
        disabled={disableForm || disabledSubmit || isPaymentChecked}
        hideSubmit={shouldHideSubmit}
        hasPricesCalcErr={hasPricesCalcErr}
        otpErrorCode={otpErrorCode}
        setOtpErrorCode={setOtpErrorCode}
        authError={authError}
        currentStep={currentStep}
        refetchAuthentication={setRefetchAuthentication}
        pulseClient={pulseClient}
        onSetPrice={setPrice}
        onClearPromoCode={clearPromoCode}
        price={price}
        isShowPromoCode={false}
        isRender={!isCarNumberSmartComponentShowing}
        activeStepHasName={activeStepHasName}
        b2pRoute={route}
      />
    </Suspense>
  );
});

SubmitAdapter.displayName = 'SubmitAdapter';
