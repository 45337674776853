import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { CarDocument } from '@entities/import-smart-components/car-document';
import { SmartComponentName } from '@product-config/constants';
import { CarDocumentAnalyticEvent } from '@pulse-smart-components-kit/car-document';
import { CarDocument as CarDocumentEnum } from '@pulse-web-platform-core/constants';
import { FallbackSkeleton } from '@shared/components';
import { withMemo } from '@shared/hocs';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, type Ref, useMemo, useCallback } from 'react';

import type {
  CarDocumentOptions,
  CarDocumentValues,
} from '@pulse-smart-components-kit/car-document';
import type { SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';
import type { AnalyticEventsMap } from '@pulse-web-platform-core/types';

import { DOCUMENTS } from './constants';

const analyticEventsMap: AnalyticEventsMap<CarDocumentAnalyticEvent> = {
  [CarDocumentAnalyticEvent.ON_DATA_SELECTED]: {
    name: analyticEvents.carDocEntered,
  },
};

export const CarDocumentAdapter = withMemo(
  observer(
    forwardRef(
      (
        {
          value,
          onChange,
          isSubmitting,
          fieldState,
        }: SmartComponentAdapterProps<Partial<CarDocumentValues>>,
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const {
          MainStore: {
            formStateStore: {
              formState: {
                CarSearch,
                UserAuthWithPassport,
                CarDocument: CarDocumentData,
              },
              updateFormValue,
            },
            productStore: { isCarNumberNotReceived },
          },
        } = useStores();

        const manufactureYear = CarSearch?.car?.manufactureYear?.value;
        const selectedDocument = CarDocumentData?.document;

        const handleChangeDocument = useCallback((document: string) => {
          updateFormValue(SmartComponentName.CAR_DOCUMENT, {
            document,
            documentNumber: '',
            documentDate: null,
          });
        }, []);

        const documents = useMemo(
          () =>
            !isCarNumberNotReceived
              ? DOCUMENTS
              : DOCUMENTS.filter(({ value }) => value !== CarDocumentEnum.STS),
          [isCarNumberNotReceived]
        );

        const options: CarDocumentOptions = useMemo(
          () => ({
            documents,
            selectedDocument,
            manufactureYear,
            handleChangeDocument,
          }),
          [selectedDocument, manufactureYear, handleChangeDocument, documents]
        );

        const handleAnalyticEventSend =
          useHandleSmartComponentsAnalyticEvents<CarDocumentAnalyticEvent>(
            analyticEventsMap
          );

        const hasAuthorized = UserAuthWithPassport?.hasAuthorized;

        if (!hasAuthorized) {
          return null;
        }

        return (
          <Suspense fallback={<FallbackSkeleton height={274} />}>
            <CarDocument
              ref={forwardRef}
              value={value}
              onChange={onChange}
              isSubmitting={isSubmitting}
              options={options}
              fieldState={fieldState}
              onAnalyticEventSend={handleAnalyticEventSend}
            />
          </Suspense>
        );
      }
    )
  )
);

CarDocumentAdapter.displayName = 'CarDocumentAdapter';
