import { B2P } from '@entities/import-smart-components/b2p';
import { FallbackSkeleton } from '@shared/components';
import { withMemo } from '@shared/hocs';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { type Ref, Suspense, forwardRef } from 'react';

import type { SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';
import type { B2PValues } from '@smart-components/index';

export const B2PAdapter = withMemo(
  observer(
    forwardRef(
      (
        {
          value,
          onChange,
          isSubmitting,
          fieldState,
        }: SmartComponentAdapterProps<Partial<B2PValues>>,
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const {
          MainStore: {
            authStore: { disableForm },
          },
        } = useStores();

        return (
          <Suspense fallback={<FallbackSkeleton height={0} transparent />}>
            <B2P
              ref={forwardRef}
              value={value}
              onChange={onChange}
              isSubmitting={isSubmitting}
              fieldState={fieldState}
              disabled={disableForm}
              options={null}
            />
          </Suspense>
        );
      }
    )
  )
);

B2PAdapter.displayName = 'B2PAdapter';
