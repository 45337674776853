import {
  B2PAdapter,
  CarDocumentAdapter,
  CarInsuranceParametersAdapter,
  CarSearchAdapter,
  CarNumberAdapter,
  DriversAdapter,
  FooterAdapter,
  PolicyholderDataAdapter,
  PolicyholderFormAdapter,
  HeaderAdapter,
  SubmitAdapter,
  CheckPolicyAdapter,
  DriversDataAdapter,
  UserAuthWithPassportAdapter,
  CarOwnerAdapter,
  PolicyImprovementAdapter,
} from '@entities/adapters';
import {
  CommonComponentName,
  SmartComponentName,
} from '@product-config/constants';

export interface ImportSmartComponents {
  [SmartComponentName.CAR_NUMBER]: typeof CarNumberAdapter;
  [SmartComponentName.CAR_SEARCH]: typeof CarSearchAdapter;
  [SmartComponentName.DRIVERS]: typeof DriversAdapter;
  [SmartComponentName.CAR_INSURANCE_PARAMETERS]: typeof CarInsuranceParametersAdapter;
  [SmartComponentName.POLICY_IMPROVEMENT]: typeof PolicyImprovementAdapter;
  [SmartComponentName.USER_AUTH_WITH_PASSPORT]: typeof UserAuthWithPassportAdapter;
  [SmartComponentName.POLICYHOLDER_DATA]: typeof PolicyholderDataAdapter;
  [SmartComponentName.POLICYHOLDER_FORM]: typeof PolicyholderFormAdapter;
  [SmartComponentName.CAR_OWNER]: typeof CarOwnerAdapter;
  [SmartComponentName.CAR_DOCUMENT]: typeof CarDocumentAdapter;
  [SmartComponentName.DRIVERS_DATA]: typeof DriversDataAdapter;
  [SmartComponentName.CHECK_POLICY]: typeof CheckPolicyAdapter;
  [SmartComponentName.B2P]: typeof B2PAdapter;
}

export const importSmartComponents: ImportSmartComponents = {
  // Step 1 - calculation:
  [SmartComponentName.CAR_NUMBER]: CarNumberAdapter,
  [SmartComponentName.CAR_SEARCH]: CarSearchAdapter,
  [SmartComponentName.DRIVERS]: DriversAdapter,

  // Step 2 - options:
  [SmartComponentName.CAR_INSURANCE_PARAMETERS]: CarInsuranceParametersAdapter,

  // Step 3 - formalization:
  [SmartComponentName.POLICY_IMPROVEMENT]: PolicyImprovementAdapter,

  // Step 4 - clients:
  [SmartComponentName.USER_AUTH_WITH_PASSPORT]: UserAuthWithPassportAdapter,
  [SmartComponentName.POLICYHOLDER_DATA]: PolicyholderDataAdapter,
  [SmartComponentName.POLICYHOLDER_FORM]: PolicyholderFormAdapter,
  [SmartComponentName.CAR_OWNER]: CarOwnerAdapter,
  [SmartComponentName.CAR_DOCUMENT]: CarDocumentAdapter,
  [SmartComponentName.DRIVERS_DATA]: DriversDataAdapter,

  // Step 5 - payment:
  [SmartComponentName.CHECK_POLICY]: CheckPolicyAdapter,

  [SmartComponentName.B2P]: B2PAdapter,
};

export interface ImportCommonComponents {
  [CommonComponentName.HEADER]: typeof HeaderAdapter;
  [CommonComponentName.FOOTER]: typeof FooterAdapter;
  [CommonComponentName.SUBMIT]: typeof SubmitAdapter;
}

export const importCommonComponents: ImportCommonComponents = {
  [CommonComponentName.HEADER]: HeaderAdapter,
  [CommonComponentName.FOOTER]: FooterAdapter,
  [CommonComponentName.SUBMIT]: SubmitAdapter,
};
