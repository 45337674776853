import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { UserAuthWithPassport } from '@entities/import-smart-components/user-auth-with-passport';
import { UserAuthWithPassportAnalyticEvent } from '@pulse-smart-components-kit/user-auth-with-passport';
import { FallbackSkeleton } from '@shared/components';
import { withMemo } from '@shared/hocs';
import { useGetDadataRequest, useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, useEffect, useMemo } from 'react';

import type { SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';
import type {
  UserAuthWithPassportOptions,
  UserAuthWithPassportValues,
} from '@pulse-smart-components-kit/user-auth-with-passport';
import type { AnalyticEventsMap } from '@pulse-web-platform-core/types';
import type { Ref } from 'react';

const analyticEventsMap: AnalyticEventsMap<UserAuthWithPassportAnalyticEvent> =
  {
    [UserAuthWithPassportAnalyticEvent.ON_DATA_SELECTED]: {
      name: analyticEvents.insurantDataSelected,
    },
    [UserAuthWithPassportAnalyticEvent.ON_PHONE_ENTERED]: {
      name: analyticEvents.insurantPhoneEntered,
    },
    [UserAuthWithPassportAnalyticEvent.ON_MAIL_ENTERED]: {
      name: analyticEvents.insurantMailEntered,
    },
  };

export const UserAuthWithPassportAdapter = withMemo(
  observer(
    forwardRef(
      (
        {
          value,
          onChange,
          isSubmitting,
          fieldState,
        }: SmartComponentAdapterProps<Partial<UserAuthWithPassportValues>>,
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const {
          MainStore: {
            applicationStore: { deviceType, phoneFromMobileApp },
            authStore: {
              disableProfileState,
              disableForm,
              otpErrorCode,
              setOtpErrorCode,
              handleAuth,
              handleOtp,
              setRefetchAuthentication,
              isUserNotDefine,
              setIsUserNotDefine,
              setIsFastAuthOpen,
              profile,
              setUseOtp,
            },
            productStore: { agentLogin },
          },
        } = useStores();

        useEffect(() => {
          if (!profile) setUseOtp(true);
        }, [profile]);

        const customRequest = useGetDadataRequest();

        const options: UserAuthWithPassportOptions = useMemo(
          () => ({
            deviceType,
            phoneFromMobileApp,
            agentLogin,
            disableProfileState,
            isOtpError: otpErrorCode,
            handleAuth,
            handleOtp,
            setRefetchAuthentication,
            isUserNotDefine,
            setIsUserNotDefine,
            setIsFastAuthOpen,
            setOtpErrorCode,
            dadataSuggestionsRequest: customRequest,
          }),
          [
            deviceType,
            phoneFromMobileApp,
            agentLogin,
            otpErrorCode,
            handleAuth,
            handleOtp,
            setRefetchAuthentication,
            isUserNotDefine,
            setIsUserNotDefine,
            setIsFastAuthOpen,
            setOtpErrorCode,
            customRequest,
          ]
        );

        const handleAnalyticEventSend =
          useHandleSmartComponentsAnalyticEvents<UserAuthWithPassportAnalyticEvent>(
            analyticEventsMap
          );

        return (
          <Suspense fallback={<FallbackSkeleton height={340} />}>
            <UserAuthWithPassport
              ref={forwardRef}
              value={value}
              onChange={onChange}
              isSubmitting={isSubmitting}
              options={options}
              fieldState={fieldState}
              disabled={disableForm}
              onAnalyticEventSend={handleAnalyticEventSend}
            />
          </Suspense>
        );
      }
    )
  )
);

UserAuthWithPassportAdapter.displayName = 'UserAuthWithPassportAdapter';
