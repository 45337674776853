import { SmartComponentName } from '@product-config/constants';
import { ErrorCode } from '@pulse-web-platform-core/constants';
import { hasError, phoneValueCasting } from '@pulse-web-platform-core/utils';
import { useStores } from '@shared/hooks';
import { useQueryAuthentication } from '@shared/queries';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { OnAnalyticEventSend } from '../types';
import type { FormBuilderUpdateErrorHandler } from '@shared/types';

import { AuthBoxAnalyticEvent } from '../constants';

export const useHandleAuthentication = (
  setFormValueError: FormBuilderUpdateErrorHandler,
  onAnalyticEventSend?: OnAnalyticEventSend
) => {
  const { t } = useTranslation();
  const {
    MainStore: {
      errorStore: { setErrorRetry },
      authStore: {
        callAuth,
        refetchAuthentication,
        authTokens,
        setRefetchAuthentication,
        setAuthIsLoading,
        setDisableForm,
        setAuthError,
        handleStoreAuthResponse,
        isFastAuthOpen,
      },
    },
  } = useStores();

  const {
    isLoading,
    error,
    data: res,
    refetch,
  } = useQueryAuthentication(phoneValueCasting(callAuth));

  useEffect(() => {
    const shouldRefetch =
      callAuth && !authTokens && (!res || refetchAuthentication);

    if (shouldRefetch) {
      refetch();

      if (refetchAuthentication) setRefetchAuthentication(false);
    }
  }, [callAuth, res, authTokens, refetchAuthentication]);

  useEffect(() => {
    setAuthIsLoading(isLoading);
    if (!isLoading) {
      if (res) {
        setErrorRetry(false);
        handleStoreAuthResponse(res);

        const event = isFastAuthOpen
          ? AuthBoxAnalyticEvent.ON_SIGNUP_START
          : AuthBoxAnalyticEvent.ON_REGISTRATION_START;

        onAnalyticEventSend?.(event);
      }
      if (hasError(error, ErrorCode.RATE_LIMIT_EXCEEDED)) {
        setDisableForm(false);
        setAuthError(true);
        setFormValueError(
          `${SmartComponentName.USER_AUTH_WITH_PASSPORT}.phone`,
          t('COMMON:errors.authLimitExceeded')
        );
      }
    }
  }, [isLoading, error, res]);

  return {
    isLoadingAuthentication: isLoading,
    errorAuthentication: error,
    resAuthentication: res,
    refetchAuthentication: refetch,
  };
};
