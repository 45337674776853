import i18n from '@app/i18n/config';
import { CarDocument } from '@pulse-web-platform-core/constants';

import { i18nDefaultValues } from './i18n';

const STS = i18n.t(
  'SMART:VehicleDocumentAdapter.labels.STS',
  i18nDefaultValues.CarDocumentAdapter.labels.STS
);
const PTS = i18n.t(
  'SMART:VehicleDocumentAdapter.labels.PTS',
  i18nDefaultValues.CarDocumentAdapter.labels.PTS
);
const EPTS = i18n.t(
  'SMART:VehicleDocumentAdapter.labels.EPTS',
  i18nDefaultValues.CarDocumentAdapter.labels.EPTS
);

export const DOCUMENTS = [
  { value: CarDocument.STS, label: STS },
  { value: CarDocument.PTS, label: PTS },
  { value: CarDocument.EPTS, label: EPTS },
];
