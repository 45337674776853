import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

import type { VehicleData } from '@pulse-web-platform-core/types';

interface Params {
  code?: string;
  brandId?: string;
  modelId?: string;
  yearId?: string;
}

interface GetPowersResponse {
  requestId: string;
  data: VehicleData[];
}

export const useQueryGetPowers = ({
  code,
  brandId,
  modelId,
  yearId,
}: Params) => {
  return useQuery(
    ['get-powers', code, brandId, modelId, yearId],
    fetchData<GetPowersResponse>({
      url: `${Endpoints.GET_BRANDS}/${brandId}/models/${modelId}/powers?year=${yearId}&productVersion=${code}`,
      method: 'GET',
    }),
    { enabled: !!code && !!brandId && !!modelId && !!yearId }
  );
};
