import { QUERY_KEYS } from '@pulse-web-platform-core/constants';
import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

import type { ResponseError } from '@pulse-web-platform-core/types';

export interface AuthenticationResponse {
  authenticationToken: string;
  confirmationId: string;
}

export const useQueryAuthentication = (phoneNumber: string) => {
  return useQuery<AuthenticationResponse, ResponseError>(
    [QUERY_KEYS.auth, phoneNumber],
    fetchData<AuthenticationResponse>({
      url: Endpoints.AUTHENTICATION,
      method: 'POST',
      data: {
        authenticationUser: { phoneNumber },
      },
    }),
    { enabled: false }
  );
};
