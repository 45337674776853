import { axiosInstance } from '@shared/utils';

import type { AxiosRequestConfig } from 'axios';

export const fetchData = <T>(options: AxiosRequestConfig) => {
  return async () => {
    const { data } = await axiosInstance<T>(options);
    return data;
  };
};
