import { SmartComponentName } from '@product-config/constants';
import { phoneDisplayValueExtCasting } from '@pulse-web-platform-core/utils';

import type { FormBuilderUpdateHandler } from '@shared/types';

type UpdateFormByMobileAppType = (
  handler: FormBuilderUpdateHandler,
  deviceType: string,
  phoneFromMobileApp: string
) => void;

export const updateFormByMobileApp: UpdateFormByMobileAppType = (
  updateFormValue,
  deviceType,
  phoneFromMobileApp
) => {
  if (deviceType && phoneFromMobileApp) {
    const formattedPhone = phoneDisplayValueExtCasting(phoneFromMobileApp)
      .trim()
      .slice(2);

    updateFormValue(
      `${SmartComponentName.USER_AUTH_WITH_PASSPORT}.client`,
      true
    );
    updateFormValue(
      `${SmartComponentName.USER_AUTH_WITH_PASSPORT}.phone`,
      formattedPhone
    );
  }
};
