import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

import type { VehicleData } from '@pulse-web-platform-core/types';

interface Params {
  code?: string;
  brandId?: string;
}
interface GetModelsResponse {
  requestId: string;
  data: VehicleData[];
}

export const useQueryGetModels = ({ code, brandId }: Params) => {
  return useQuery(
    ['get-models', code, brandId],
    fetchData<GetModelsResponse>({
      url: `${Endpoints.GET_BRANDS}/${brandId}/models?productVersion=${code}`,
      method: 'GET',
    }),
    { enabled: !!code && !!brandId }
  );
};
