import i18n from '@app/i18n/config';
import { Footer } from '@entities/import-smart-components/footer';
import { formattingPrice } from '@pulse-web-platform-core/utils';
import { FallbackSkeleton } from '@shared/components';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { Suspense, useEffect, useMemo, useState } from 'react';

import { useGetItems, useGetRequestDate } from './hooks';
import { useQueryGetPrices } from './queries';

export const FooterAdapter = observer(() => {
  const {
    MainStore: {
      applicationStore: {
        currentStep,
        flowConfig: { maxSteps },
      },
      productStore: { orderData },
    },
  } = useStores();
  const [prevPrice, setPrevPrice] = useState('0');

  const requestData = useGetRequestDate();
  const { data } = useQueryGetPrices(requestData);

  const items = useGetItems();

  const isLastStep = maxSteps === currentStep;

  const main = useMemo(() => {
    const price = isLastStep
      ? orderData?.premiumAndDelta || prevPrice
      : data?.premiumAndDelta || prevPrice;

    return {
      title: i18n.t('COMMON:labels.price'),
      value: formattingPrice(Number(price)),
      isMain: true,
    };
  }, [
    data?.premiumAndDelta,
    prevPrice,
    orderData?.premiumAndDelta,
    isLastStep,
  ]);

  useEffect(() => {
    const price = isLastStep
      ? orderData?.premiumAndDelta
      : data?.premiumAndDelta;

    if (price) {
      setPrevPrice(price);
    }
  }, [data?.premiumAndDelta, orderData?.premiumAndDelta, isLastStep]);

  return (
    <Suspense fallback={<FallbackSkeleton height={274} />}>
      <Footer main={main} items={items} />
    </Suspense>
  );
});
