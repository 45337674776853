import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { CarNumber } from '@entities/import-smart-components/car-number';
import {
  type CarNumberValues,
  CarNumberAnalyticEvent,
} from '@pulse-smart-components-kit/car-number';
import { FallbackSkeleton } from '@shared/components';
import { withMemo } from '@shared/hocs';
import { useHandleManualInput, useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, useMemo } from 'react';

import type { SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';
import type { AnalyticEventsMap } from '@pulse-web-platform-core/types';
import type { Ref } from 'react';

import { useHandleCarNumber } from './queries';

const analyticEventsMap: AnalyticEventsMap<CarNumberAnalyticEvent> = {
  [CarNumberAnalyticEvent.ON_REG_NUMBER_SELECTED]: {
    name: analyticEvents.regNumberSelected,
  },
  [CarNumberAnalyticEvent.ON_REG_NUMBER_SEARCH]: {
    name: analyticEvents.regNumberSearch,
  },
  [CarNumberAnalyticEvent.ON_REG_NUMBER_NOT_RECEIVED]: {
    name: analyticEvents.regNumberNotReceived,
  },
  [CarNumberAnalyticEvent.ON_SELECT_CAR_DATA]: {
    name: analyticEvents.selectCarData,
  },
};

export const CarNumberAdapter = withMemo(
  observer(
    forwardRef(
      (
        {
          value,
          onChange,
        }: SmartComponentAdapterProps<Partial<CarNumberValues>>,
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const {
          MainStore: {
            productStore: {
              isCarNumberSmartComponentShowing,
              isCarNumberNotReceived,
              setIsCarNumberNotReceived,
            },
            formStateStore: {
              formState: { CarSearch },
            },
          },
        } = useStores();

        const carNumber = isCarNumberSmartComponentShowing
          ? value?.carNumber
          : CarSearch?.carNumber;

        const { handleFindAuto } = useHandleCarNumber(carNumber);

        const handleManualInput = useHandleManualInput(carNumber);

        const options = useMemo(
          () => ({
            onFindAuto: handleFindAuto,
            onManualInput: handleManualInput,
            isCarNumberNotReceived,
            setIsCarNumberNotReceived,
          }),
          [
            handleFindAuto,
            handleManualInput,
            isCarNumberNotReceived,
            setIsCarNumberNotReceived,
          ]
        );

        const handleAnalyticEventSend =
          useHandleSmartComponentsAnalyticEvents<CarNumberAnalyticEvent>(
            analyticEventsMap
          );

        if (!isCarNumberSmartComponentShowing) return;

        return (
          <Suspense fallback={<FallbackSkeleton height={274} />}>
            <CarNumber
              value={value}
              ref={forwardRef}
              onChange={onChange}
              isSubmitting={false}
              options={options}
              onAnalyticEventSend={handleAnalyticEventSend}
            />
          </Suspense>
        );
      }
    )
  )
);

CarNumberAdapter.displayName = 'CarNumberAdapter';
