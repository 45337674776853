import i18n from '@app/i18n/config';
import { useMemo } from 'react';

import type { PolicyholderDataOptions } from '@pulse-smart-components-kit/policyholder-data';

export const useGetDescriptionItems = ({
  name,
  middlename,
  surname,
  birthday,
  phone,
  email,
  address,
  passport,
  passportCode,
  passportByWho,
  passportDate,
}: PolicyholderDataOptions) => {
  return useMemo(
    () => [
      {
        key: 1,
        subTitle: `${surname} ${name} ${middlename}`,
        description: (
          <>
            {birthday}
            <br />
            {phone && email && `${phone} · ${email}`}
          </>
        ),
      },
      {
        key: 2,
        subTitle: i18n.t('COMMON:headers.pasport'),
        description: (
          <>
            {passport}
            <br />
            {i18n.t('COMMON:labels.passportByWho')} {passportByWho}
            <br />
            {i18n.t('COMMON:labels.passportCode')} {passportCode}
            <br />
            {i18n.t('COMMON:labels.passportDate')} {passportDate}
          </>
        ),
      },
      {
        key: 3,
        subTitle: i18n.t('COMMON:headers.address'),
        description: address,
      },
    ],
    [
      name,
      middlename,
      surname,
      birthday,
      phone,
      email,
      address,
      passport,
      passportCode,
      passportByWho,
      passportDate,
    ]
  );
};
