import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

import type { VehicleData } from '@pulse-web-platform-core/types';

interface GetBrandsResponse {
  requestId: string;
  data: VehicleData[];
}

export const useQueryGetBrands = (code: string | undefined) => {
  return useQuery(
    ['get-brands', code],
    fetchData<GetBrandsResponse>({
      url: `${Endpoints.GET_BRANDS}?productVersion=${code}`,
      method: 'GET',
    }),
    { enabled: false }
  );
};
