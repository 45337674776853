import { QUERY_KEYS } from '@pulse-web-platform-core/constants';
import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

import type { ResponseError } from '@pulse-web-platform-core/types';

interface ConfirmAuthenticationParams {
  code: string;
  confirmationId?: string;
  authToken?: string;
}

export interface ConfirmAuthenticationResponse {
  accessToken: string;
  isVip: boolean;
  userId: string;
  name: string;
  csrfToken: string;
}

export const useQueryConfirmAuthentication = ({
  code,
  confirmationId,
  authToken,
}: ConfirmAuthenticationParams) => {
  return useQuery<ConfirmAuthenticationResponse, ResponseError>(
    [QUERY_KEYS.confirmAuth, code, confirmationId],
    fetchData<ConfirmAuthenticationResponse>({
      url: Endpoints.CONFIRMAUTH,
      method: 'POST',
      data: { code, confirmationId },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }),
    { enabled: false }
  );
};
