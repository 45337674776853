import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

import type { GetPriceRequest, GetPriceResponse } from './types';

import { requestBody } from '../../schemas';

export const useQueryGetPrices = (data: GetPriceRequest) => {
  const enabled = requestBody.isValidSync(data);

  return useQuery(
    ['get-prices', data],
    fetchData<GetPriceResponse>({
      url: Endpoints.GET_PRICES,
      method: 'POST',
      data,
    }),
    { enabled }
  );
};
