/* eslint-disable @typescript-eslint/no-explicit-any */
import { analyticEvents } from '@app/web-analytic';
import { useStores } from '@shared/hooks';
import amplitude from 'amplitude-js';
import { memo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import ym, { YMInitializer } from 'react-yandex-metrika';

const YM_COUNTER_KEY = Number(window.envKeys.YM_KASKO_COUNTER_KEY);
const AMPLITUDE_API_KEY = String(window.envKeys.AMPLITUDE_API_KEY);
const ymInitEventType = `yacounter${YM_COUNTER_KEY}inited`;

export const WebAnalytic = memo(() => {
  const {
    MainStore: {
      applicationStore: { sendSingleAnalyticEvent },
    },
  } = useStores();

  const [searchParams] = useSearchParams();
  const utmPayload: Record<string, any> = {};
  const clientCategory = localStorage.getItem('clientCategory');
  // const { pathname } = useLocation();

  // useEffect(() => {
  //   if (
  //     pathname !== PAYMENT_ROUTE &&
  //     pathname !== PAYMENT_ERROR_ROUTE &&
  //     pathname !== PAYMENT_SUCCESS_ROUTE &&
  //     pathname !== PAYMENT_IN_PROGRESS_ROUTE
  //   ) {
  //     clearLocalStorage();
  //   }
  // }, []);

  useEffect(() => {
    amplitude.getInstance().init(AMPLITUDE_API_KEY, undefined, {
      platform: searchParams.get('platform') || 'Web',
      includeUtm: true,
    });

    amplitude.getInstance().setUserProperties({
      media_source: searchParams.get('media_source') || '0000',
      campaign: searchParams.get('campaign') || 'WEB',
      utm_source: searchParams.get('utm_source') || 0,
      utm_medium: searchParams.get('utm_medium') || 0,
      utm_content: searchParams.get('utm_content') || 0,
      wm_id: searchParams.get('wm_id') || 0,
      utm_campaign: searchParams.get('utm_campaign') || 0,
    });
  }, [AMPLITUDE_API_KEY]);

  useEffect(() => {
    const addUserParams = () => {
      ym('userParams', {
        clientCategory: clientCategory,
      });
    };

    amplitude.getInstance().setUserProperties({
      clientCategory: clientCategory,
    });

    document.addEventListener(ymInitEventType, addUserParams);

    return () => document.removeEventListener(ymInitEventType, addUserParams);
  }, [clientCategory]);

  useEffect(() => {
    if (searchParams.get('utm_source')) {
      utmPayload.utm_source = searchParams.get('utm_source');
    }

    if (searchParams.get('utm_campaign')) {
      utmPayload.utm_campaign = searchParams.get('utm_campaign');
    }

    if (searchParams.get('utm_content')) {
      utmPayload.utm_content = searchParams.get('utm_content');
    }

    if (searchParams.get('utm_medium')) {
      utmPayload.utm_medium = searchParams.get('utm_medium');
    }

    if (searchParams.get('utm_media_source')) {
      utmPayload.utm_media_source = searchParams.get('utm_media_source');
    }
    if (searchParams.get('campaign')) {
      utmPayload.campaign = searchParams.get('campaign');
    }
    if (searchParams.get('media_source')) {
      utmPayload.media_source = searchParams.get('media_source');
    }
    if (searchParams.get('wm_id')) {
      utmPayload.wm_id = searchParams.get('wm_id');
    }

    // dispatch({
    //   type: UserActionTypes.SetRegUtm,
    //   payload: utmPayload,
    // });
  }, []);

  useEffect(() => {
    sendSingleAnalyticEvent(analyticEvents.webStart);
  }, []);

  return (
    <YMInitializer
      accounts={[YM_COUNTER_KEY]}
      options={{
        triggerEvent: true,
        clickmap: true,
        childIframe: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        trustedDomains: ['https://pulse.rusrobots.ru/', 'https://pulse.insure'],
        params: {
          media_source: searchParams.get('media_source') || '0000',
          campaign: searchParams.get('campaign') || 'WEB',
          utm_source: searchParams.get('utm_source') || 0,
          utm_medium: searchParams.get('utm_medium') || 0,
          utm_content: searchParams.get('utm_content') || 0,
          wm_id: searchParams.get('wm_id') || 0,
          utm_campaign: searchParams.get('utm_campaign') || 0,
          clientCategory: 'STANDART',
        },
      }}
      version="2"
    />
  );
});
