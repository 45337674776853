import type { CarInsuranceParametersValues } from '@pulse-smart-components-kit/car-insurance-parameters';
import type { ExtraParam } from '@shared/types';

const defaultValue: CarInsuranceParametersValues = {
  region: '',
  repair: '',
  period: {
    startDate: new Date(),
    period: '',
  },
  franchise: '',
  isValid: false,
};

/* eslint-disable indent */
export const getDefaultValues = (
  defaultProductValues: ExtraParam[] = [],
  minDate: Date,
  region?: string
) => {
  const defaultValues =
    defaultProductValues.reduce<CarInsuranceParametersValues>((acc, item) => {
      switch (item.code) {
        case 'defaultVehicleRepairType':
          acc.repair = item.value;
          break;

        case 'defaultRegion':
          acc.region = region ?? item.value;
          break;

        case 'defaultInsuranceProductPeriod':
          acc.period.startDate = minDate;
          acc.period.period = item.value;
          break;
      }

      return acc;
    }, defaultValue);

  return defaultValues;
};
