import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

import type { VehicleData } from '@pulse-web-platform-core/types';

interface Params {
  code?: string;
  brandId?: string;
  modelId?: string;
}

interface GetYearsResponse {
  requestId: string;
  data: VehicleData[];
}

export const useQueryGetYears = ({ code, brandId, modelId }: Params) => {
  return useQuery(
    ['get-years', code, brandId, modelId],
    fetchData<GetYearsResponse>({
      url: `${Endpoints.GET_BRANDS}/${brandId}/models/${modelId}/years?productVersion=${code}`,
      method: 'GET',
    }),
    { enabled: !!code && !!brandId && !!modelId }
  );
};
