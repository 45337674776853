import type { ThemeConfig } from '@pulse-web-platform-core/types';

export const getThemeURL = (
  res: ThemeConfig | undefined,
  partnername: string | undefined,
  type: 'ui' | 'i18n' | 'auth-flow' | 'icons' | 'flows',
  configType: 'theme-url' | 'texts-url' | 'auth-url' | 'icons-url' | 'flow-url'
) => {
  if (res && res[configType] && partnername) {
    return `/${String(partnername)}/${type}/${String(res[configType])}.json`;
  }

  return '';
};
