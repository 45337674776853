import { useHandleErrorRequest } from '@shared/hooks';
import { observer } from 'mobx-react-lite';

import {
  useHandleQueries,
  useHandleMobileParams,
  useHandleSessionRecovery,
  useDeveloperMode,
} from './hooks';
import { useQueryProduct } from './queries';

export const InitBox = observer(() => {
  useDeveloperMode();

  useHandleQueries();
  useHandleMobileParams(); // TODO: Нужен ли нам это хук

  //Запрос на инициализацию продукта
  const { status, refetch, error, isLoading } = useQueryProduct();
  useHandleErrorRequest({ status, refetch, error, isLoading });

  useHandleSessionRecovery();

  return null;
});
