import { PolicyholderForm } from '@entities/import-smart-components/policyholder-form';
import { SmartComponentName } from '@product-config/constants';
import { useUpdateEffect } from '@pulse-smart-components-kit/common';
import { AddressType, FiasLevel } from '@pulse-web-platform-core/constants';
import { useIsDesktop } from '@pulse-web-platform-core/hooks';
import { FallbackSkeleton } from '@shared/components';
import { withMemo } from '@shared/hocs';
import { useGetDadataRequest, useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import {
  Suspense,
  forwardRef,
  useEffect,
  useMemo,
  useState,
  type Ref,
} from 'react';

import type { SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';
import type {
  PolicyholderFormValues,
  PolicyholderFormDisabledFields,
  PolicyholderFormOptions,
} from '@pulse-smart-components-kit/policyholder-form';

export const PolicyholderFormAdapter = withMemo(
  observer(
    forwardRef(
      (
        {
          value,
          onChange,
          isSubmitting,
          fieldState,
        }: SmartComponentAdapterProps<Partial<PolicyholderFormValues>>,
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const isDesktop = useIsDesktop();
        const {
          MainStore: {
            authStore: { disableForm, profile: storeProfile },
            productStore: {
              agentLogin,
              isPolicyholderTheOwner,
              isEmailNotUnique,
              setIsEmailNotUnique,
            },
            formStateStore: {
              formState: { UserAuthWithPassport, CarOwner },
              updateFormValue,
            },
            applicationStore: { isRegistration },
          },
        } = useStores();

        const profile = storeProfile?.profile;
        const {
          cardSeries: profileCardSeries,
          cardNumber: profileCardNumber,
          cardIssuerName: profileCardIssuerName,
          cardIssuerCode: profileCardIssuerCode,
          cardIssueDate: profileCardIssueDate,
          addresses,
        } = profile?.passport ?? {};

        const registrationAddress = addresses?.find(
          ({ addressType }) => addressType === AddressType.REGISTRATION
        );

        const hasAddressCodeInProfile = Boolean(
          registrationAddress?.addressCode
        );

        const [disabledFields, setDisabledFields] =
          useState<PolicyholderFormDisabledFields>(() => ({
            birthday: false,
            middlename: false,
            name: false,
            surname: false,
            passport: false,
            passportDate: false,
            passportByWho: false,
            passportCode: false,
            address: false,
            phone: false,
            email: false,
          }));

        const hasAuthorized = UserAuthWithPassport?.hasAuthorized;

        const fiasLevel = UserAuthWithPassport?.address?.fias_level;

        const isAvailableFiasLevel =
          fiasLevel === FiasLevel.HOUSE || fiasLevel === FiasLevel.APARTMENT;

        useEffect(() => {
          if (hasAuthorized) {
            setDisabledFields({
              name: !!profile?.firstName,
              surname: !!profile?.lastName,
              phone: !!profile?.phone,
              birthday: !!profile?.birthDate,
              middlename: !!profile?.middleName,
              passport: !!profileCardSeries && !!profileCardNumber,
              passportDate: !!profileCardIssueDate,
              passportByWho: !!profileCardIssuerName,
              passportCode: !!profileCardIssuerCode,
              address: hasAddressCodeInProfile && isAvailableFiasLevel,
              email: false,
            });
          }
        }, [
          hasAuthorized,
          isAvailableFiasLevel,
          hasAddressCodeInProfile,
          profile?.firstName,
          profile?.lastName,
          profile?.phone,
          profile?.birthDate,
          profile?.middleName,
          profileCardSeries,
          profileCardNumber,
          profileCardIssueDate,
          profileCardIssuerName,
          profileCardIssuerCode,
        ]);

        useEffect(() => {
          const hasAddress = UserAuthWithPassport?.address?.fias_id;
          if (hasAuthorized && hasAddress && hasAddressCodeInProfile) {
            setDisabledFields((prev) => ({
              ...prev,
              address: true,
            }));
          }
        }, [
          hasAuthorized,
          hasAddressCodeInProfile,
          UserAuthWithPassport?.address?.value,
          UserAuthWithPassport?.address?.fias_id,
          UserAuthWithPassport?.address?.fias_level,
        ]);

        useEffect(() => {
          if (isPolicyholderTheOwner) {
            // Изменение формы CarOwner если выбрано "Страхователь является владельцем"
            updateFormValue(SmartComponentName.CAR_OWNER, {
              ...CarOwner,
              birthday: value?.birthday,
              middlename: value?.middlename,
              name: value?.name,
              surname: value?.surname,
              passport: value?.passport,
              passportDate: value?.passportDate,
              passportByWho: value?.passportByWho,
              passportCode: value?.passportCode,
              address: value?.address,
            });
          }
        }, [
          value?.birthday?.toString(),
          value?.middlename,
          value?.name,
          value?.surname,
          value?.passport,
          value?.passportDate?.toString(),
          value?.passportByWho,
          value?.passportCode,
          value?.address?.value,
          value?.address?.fias_id,
          value?.address?.fias_level,
        ]);

        useEffect(() => {
          if (isRegistration) {
            updateFormValue(SmartComponentName.POLICYHOLDER_FORM, {
              ...value,
              isValid: true,
            });
          }
        }, [isRegistration]);

        useUpdateEffect(() => {
          if (isEmailNotUnique) {
            setIsEmailNotUnique(false);
          }
        }, [value?.email]);

        useEffect(() => {
          updateFormValue(SmartComponentName.POLICYHOLDER_FORM, {
            ...value,
            isValid: isEmailNotUnique ? false : value?.isValid,
          });
        }, [isEmailNotUnique]);

        const customRequest = useGetDadataRequest();

        const options: PolicyholderFormOptions = useMemo(
          () => ({
            agentLogin,
            disabledFields,
            isEmailNotUnique,
            dadataSuggestionsRequest: customRequest,
          }),
          [agentLogin, disabledFields, isEmailNotUnique, customRequest]
        );

        if (!hasAuthorized || isRegistration) {
          return null;
        }

        return (
          <Suspense
            fallback={<FallbackSkeleton height={isDesktop ? 640 : 920} />}
          >
            <PolicyholderForm
              ref={forwardRef}
              value={value}
              onChange={onChange}
              isSubmitting={isSubmitting}
              options={options}
              disabled={disableForm}
              fieldState={fieldState}
            />
          </Suspense>
        );
      }
    )
  )
);

PolicyholderFormAdapter.displayName = 'PolicyholderFormAdapter';
