import { withMemo } from '@shared/hocs';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';

import type { FormBuilderStructure } from '@shared/types';

interface Props {
  components: FormBuilderStructure;
}

export const FormBuilderFooter = withMemo(
  observer(({ components }: Props) => {
    const {
      MainStore: {
        applicationStore: { isFooterShowing },
      },
    } = useStores();

    if (!isFooterShowing) return;

    return components.footer.map(
      ({ component: Component, props: { name } }, index) => (
        <Component key={`${name}-${index}`} />
      )
    );
  })
);

FormBuilderFooter.displayName = 'FormBuilderFooter';
