import { useStores } from '@shared/hooks';
import { getRisks } from '@shared/utils';

import type { SubmitRequest } from '../types';

import { OBJECT_TYPE } from '../constants';
import {
  getInsuranceObject,
  getVehicleDrivers,
  getVehicleOwner,
} from '../utils';

export const useGetRequestData = (): SubmitRequest => {
  const {
    MainStore: {
      initProductStore: {
        initState: { code },
      },
      formStateStore: {
        formState: {
          CarSearch,
          CarOwner,
          CarInsuranceParameters,
          PolicyImprovement,
          CarDocument,
          DriversData,
        },
      },
    },
  } = useStores();

  const effectiveSince = (
    CarInsuranceParameters?.period?.startDate ?? new Date()
  ).toJSON();
  const price = Number(CarSearch?.marketPrice) || 0;

  const baseParameters = {
    effectiveSince,
    productCode: code ?? '',
    contractDuration: CarInsuranceParameters?.period?.period || '',
    riskObjects: getRisks({
      insuranceRisks: PolicyImprovement,
      amount: price,
    }),
  };

  const productProperty = {
    kind: OBJECT_TYPE,
    hasActiveKasko: Boolean(CarSearch?.haveValidPolicy),
    insuranceSum: price,
    vehicleOwner: getVehicleOwner(CarOwner),
    vehicleDrivers: getVehicleDrivers(DriversData?.drivers || []),
    insuranceObject: getInsuranceObject({
      carSearch: CarSearch,
      insuranceParameters: CarInsuranceParameters,
      carDocument: CarDocument,
    }),
  };

  return { baseParameters, productProperty };
};
