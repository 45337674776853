import {
  useQueryVehicleRegion,
  useQueryVehicleRepairType,
} from '@entities/adapters/car-insurance-parameters-adapter/queries';
import { formattingPrice } from '@pulse-web-platform-core/utils';
import { PolicyIncludedAccordion } from '@pulse-web-ui/policy-included-accordion';
import { Spinner } from '@pulse-web-ui/spinner';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { i18nDefaultValues } from '../../i18n';

interface TotalPriceAccordionProps {
  insuranceSum: number;
  regionCode: string;
  repairType: string;
  franchise: number;
  productCode: string;
}

export const TotalPriceAccordion = ({
  insuranceSum,
  regionCode,
  repairType,
  franchise,
  productCode,
}: TotalPriceAccordionProps) => {
  const { t } = useTranslation();

  const { data: dataVehicleRegion, isLoading: isLoadingVehicleRegion } =
    useQueryVehicleRegion(productCode);

  const { data: dataRepair, isLoading: isLoadingRepair } =
    useQueryVehicleRepairType(productCode);

  const region = useMemo(() => {
    return (
      dataVehicleRegion?.vehicleRegions.find((item) => item.code === regionCode)
        ?.name ?? ''
    );
  }, [dataVehicleRegion?.vehicleRegions]);

  const repair = useMemo(() => {
    return (
      dataRepair?.vehicleRepairTypes?.find((item) => item.code === repairType)
        ?.name ?? ''
    );
  }, [dataRepair?.vehicleRepairTypes]);

  return isLoadingVehicleRegion || isLoadingRepair ? (
    <Spinner />
  ) : (
    <PolicyIncludedAccordion
      header={t('SMART:CheckPolicyAdapter.totalPrice.header', {
        defaultValue: i18nDefaultValues.CheckPolicyAdapter.totalPrice.header,
      })}
      subHeader={formattingPrice(insuranceSum)}
      productItems={[
        {
          title: t('SMART:CheckPolicyAdapter.totalPrice.region', {
            defaultValue:
              i18nDefaultValues.CheckPolicyAdapter.totalPrice.region,
          }),
          value: region,
        },
        {
          title: t('SMART:CheckPolicyAdapter.totalPrice.repair', {
            defaultValue:
              i18nDefaultValues.CheckPolicyAdapter.totalPrice.repair,
          }),
          value: repair,
        },
        {
          title: t('SMART:CheckPolicyAdapter.totalPrice.franchise', {
            defaultValue:
              i18nDefaultValues.CheckPolicyAdapter.totalPrice.franchise,
          }),
          value: formattingPrice(franchise),
        },
      ]}
    />
  );
};
