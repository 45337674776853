import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

import type { DaDataAddress, DaDataSuggestion } from '@pulse-web-ui/dadata';
import type { InferType } from 'yup';

import { schema } from './schema';

export interface GetDadataSuggestionsResponse {
  suggestions:
    | DaDataSuggestion<DaDataAddress>
    | DaDataSuggestion<DaDataAddress>[];
}

export type GetDadataSuggestionsRequestData = InferType<typeof schema>;

export const useQueryGetDadataSuggestions = (
  data: GetDadataSuggestionsRequestData
) => {
  const enabled = schema.isValidSync(data);

  return useQuery(
    ['get-dadata-suggestions', data],
    fetchData<GetDadataSuggestionsResponse>({
      url: `${Endpoints.DADATA}/suggest/address`,
      method: 'POST',
      data,
    }),
    { enabled }
  );
};
